import { Button, message, Pagination, Spin, Table } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import { LIMApp } from "../../modules";
import styles from './style.module.css'


interface SendMsgListState {
    data: any[]
    page: number
    pageSize: number | undefined
    totalCount: number
    visible: boolean
    content: string
    spinning: boolean
}

class SendMsgList extends Component<any, SendMsgListState>{
    constructor(props: any) {
        super(props)
        this.state = {
            spinning: true,
            pageSize: 15,
            content: '',
            visible: false,
            data: [],
            page: 1,
            totalCount: 1,
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/message?page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.list.length; i++) {
                let msg = resp.data.list[i]
                let senderAvatar = LIMApp.shared().getAvatarUrl(msg.sender, LIMApp.shared().channelPersonal)
                let receiverAvatar = LIMApp.shared().getAvatarUrl(msg.receiver, msg.receiver_channel_type)
                let handlerAvatar = LIMApp.shared().getAvatarUrl(msg.handler_uid, LIMApp.shared().channelPersonal)
                list.push({
                    senderName: msg.sender_name,
                    sender: msg.sender,
                    senderAvatar: <div><img alt='' className={styles.avatar} src={senderAvatar} /></div>,
                    receiver: msg.receiver,
                    receiverName: msg.receiver_name,
                    receiverAvatar: <div><img alt='' className={styles.avatar} src={receiverAvatar} /></div>,
                    channelType: msg.receiver_channel_type === 1 ? '单聊' : '群聊',
                    handlerName: msg.handler_name,
                    handlerUID: msg.handler_uid,
                    handlerAvatar: <div><img alt='' className={styles.avatar} src={handlerAvatar} /></div>,
                    content: msg.content,
                    time: msg.created_at,
                })
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count,
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }

    showTotal(total: any) {
        return `共${total}条数据`
    }
    sendMsgDialogCancel() {
        this.setState({
            visible: false
        }, () => {

        })
    }

    sendMsgDialogOk() {
        this.sendMsg()
    }
    showSendDialog() {
        this.setState({
            visible: true
        }, () => {

        })
    }
    contentChange(v: any) {
        this.setState({ content: v.target.value })
    }
    sendMsg() {
        if (!this.state.content || this.state.content === '') {
            message.warn('请输入消息内容')
            return
        }
        const then = this

        axios.post('manager/message/sendall', {
            "content": then.state.content,
        }).then(function (resp) {
            then.setState({
                content: '',
                visible: false
            })
            message.success('发送成功')
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    render() {
        const { data, page, totalCount, visible, content, spinning, pageSize } = this.state
        const columns = [
            {
                title: '发送者名字',
                dataIndex: 'senderName',
                fixed: true,
                width: 50,
            },
            {
                title: '发送者ID',
                dataIndex: 'sender',
                width: 50,
            },
            {
                title: '发送者头像',
                dataIndex: 'senderAvatar',
                width: 30,
            },
            {
                title: '接受者ID',
                dataIndex: 'receiver',
                width: 80,
            },
            {
                title: '接受者名字',
                dataIndex: 'receiverName',
                width: 50,
            },
            {
                title: '接受者头像',
                dataIndex: 'receiverAvatar',
                width: 50,
            },
            {
                title: '聊天类型',
                dataIndex: 'channelType',
                width: 20,
            },
            {
                title: '操作者id',
                dataIndex: 'handlerUID',
                width: 30,
            },
            {
                title: '操作者名字',
                dataIndex: 'handlerName',
                width: 50,
            },
            {
                title: '操作者头像',
                dataIndex: 'handlerAvatar',
                width: 50,
            },
            {
                title: '消息内容',
                dataIndex: 'content',
                width: 100,
            },
            {
                title: '消息发送时间',
                dataIndex: 'time',
                width: 30,
            }
        ]
        return (
            <div>
                <div style={{ display: "inline-flex" }}>
                    <p style={{ lineHeight: "2.3", marginRight: "10px" }}>发送消息记录</p>
                    <Button type="primary" onClick={this.showSendDialog.bind(this)}>发消息</Button>
                </div>
                <Spin spinning={spinning}>
                    <Table scroll={{ x: 2500, y: 'auto' }} columns={columns} dataSource={data} pagination={false} />
                    <Pagination defaultPageSize={pageSize} showSizeChanger={true} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>
                <Modal centered title='发消息' visible={visible} onCancel={this.sendMsgDialogCancel.bind(this)} onOk={this.sendMsgDialogOk.bind(this)} okText='发送' cancelText='取消'>
                    <p>给所有用户发送一条信息</p>
                    <TextArea style={{ marginTop: '10px' }} rows={5} placeholder="请输入内容" value={content} onChange={this.contentChange.bind(this)}></TextArea>
                </Modal>
            </div >
        )
    }
}

export default SendMsgList