import report from ".";
import { LIMApp, LIMRoute } from "../../modules";
import { LIMContext, LIMModule } from "../../modules/base/module";



export class ReportModule implements LIMModule {
    getModuleID() {
        return 'report'
    }

    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/reportlist', report))
    }
}