import { Component } from "react"
import { withRouter } from 'react-router'
import { Button, DatePicker, Dropdown, Menu, message } from 'antd'
import moment from 'moment'
import styles from './style.module.css'
import axios from "axios"
import { Line, Column, Area } from '@ant-design/charts';
import DownOutlined from '@ant-design/icons'
import { LiMUtils } from "../modules/common/utils"
import locale from '../locale/zh-cn/locale'
// 设置日期控件显示中文
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
interface WelcomeState {
    nowDate: string
    lastMonthDate: string
    totalUserCount: number
    totalGroupCount: number
    registerUserCount: number
    createdGroupCount: number
    registerUserWithSpace: any[]
    registerUserWithSpaceSortList: any[]
    createGroupWithSpace: any[]
    createGroupWithSpaceSortList: any[]
}
class Welcome extends Component<any, WelcomeState> {
    constructor(props: any) {
        super(props)
        let nowDate = LiMUtils.getNowDateYYYYMMDD()
        console.log('当前时间', nowDate)
        let lastMonthDate = LiMUtils.getLastMonthDateYYYYMMDD()
        this.state = {
            nowDate: nowDate,
            lastMonthDate: lastMonthDate,
            totalUserCount: 0,
            totalGroupCount: 0,
            registerUserCount: 0,
            createdGroupCount: 0,
            registerUserWithSpace: [],
            registerUserWithSpaceSortList: [],
            createGroupWithSpace: [],
            createGroupWithSpaceSortList: []
        }
        this.getData()
        this.getRegisterUserCountWithSpace(lastMonthDate, nowDate)
        this.getCreateGroupWithDateSpace(lastMonthDate, nowDate)
    }
    getData() {
        const then = this
        axios.get(`statistics/countnum?date=${this.state.nowDate}`).then(function (resp) {
            then.setState({
                totalUserCount: resp.data.user_total_count,
                totalGroupCount: resp.data.group_total_count,
                registerUserCount: resp.data.register_count,
                createdGroupCount: resp.data.group_create_count,
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    onChange(date: any, dateString: any) {
        this.setState({
            nowDate: dateString
        }, () => {
            this.getData()
        })
    }
    onCreateGroupChnageDate(date: any, dateString: any) {
        this.getCreateGroupWithDateSpace(dateString[0], dateString[1])
    }
    onRegisterUserDate(date: any, dateString: any) {
        this.getRegisterUserCountWithSpace(dateString[0], dateString[1])
    }
    getCreateGroupWithDateSpace(start: string, end: string) {
        const then = this
        axios.get(`statistics/createdgroup/${start}/${end}`).then(function (resp) {
            const list = []
            const sortList = []
            for (var key in resp.data) {
                list.push({
                    time: key,
                    value: resp.data[key]
                })
                sortList.push({
                    time: key,
                    value: resp.data[key]
                })
            }
            then.setState({
                createGroupWithSpace: list
            })
            sortList.sort(function (a, b) {
                return b.value - a.value
            })
            let createGroupSortList = []
            for (let i = 0; i < sortList.length; i++) {
                var num = i + 1
                createGroupSortList.push(
                    <div style={{ marginBottom: '15px', width: '100%' }}>
                        <label className={styles.span} style={{ backgroundColor: i === 0 ? '#0050b3' : i === 1 ? '#096dd9' : i === 2 ? '#1890ff' : 'ddd', color: (i === 0 || i === 1 || i === 2) ? '#fff' : '#000' }}>
                            {num}
                        </label>
                        <label className={styles.span_content}>
                            {sortList[i].time}
                        </label>
                        <label style={{ float: 'right' }}>{sortList[i].value}</label>
                    </div>
                )
                if (num === 10) {
                    break
                }
            }
            then.setState({
                createGroupWithSpaceSortList: createGroupSortList
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    getRegisterUserCountWithSpace(start: string, end: string) {
        const then = this
        axios.get(`statistics/registeruser/${start}/${end}`).then(function (resp) {
            const list = []
            const sortList = []
            for (var key in resp.data) {
                list.push({
                    time: key,
                    value: resp.data[key]
                })
                sortList.push({
                    time: key,
                    value: resp.data[key]
                })
            }
            then.setState({
                registerUserWithSpace: list
            })

            sortList.sort(function (a, b) {
                return b.value - a.value
            })
            let registerUserWithSpaceSortList = []
            for (let i = 0; i < sortList.length; i++) {
                var num = i + 1
                registerUserWithSpaceSortList.push(
                    <div style={{ marginBottom: '15px', width: '100%' }}>
                        <label className={styles.span} style={{ backgroundColor: i === 0 ? '#0050b3' : i === 1 ? '#096dd9' : i === 2 ? '#1890ff' : 'ddd', color: (i === 0 || i === 1 || i === 2) ? '#fff' : '#000' }}>
                            {num}
                        </label>
                        <label className={styles.span_content}>
                            {sortList[i].time}
                        </label>
                        <label style={{ float: 'right' }}>{sortList[i].value}</label>
                    </div>
                )
                if (num === 10) {
                    break
                }
            }
            then.setState({
                registerUserWithSpaceSortList: registerUserWithSpaceSortList
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    handleMenuClick(v: any) {

    }
    render() {
        const data = []
        for (let i = 0; i < 30; i++) {
            data.push({
                time: `2021-${i}-${i}`,
                value: Math.floor(Math.random() * (1000 - 0)) + 0
            })
        }
        const groupCreated = []
        for (let i = 0; i < 50; i++) {
            groupCreated.push({
                time: `2021-${i}-${i}`,
                value: Math.floor(Math.random() * (1000 - 0)) + 0
            })
        }
        const userActivity = []
        for (let i = 0; i < 10; i++) {
            userActivity.push({
                name: `用户${i}`,
                value: Math.floor(Math.random() * (500 - 0)) + 0
            })
        }
        const groupActivity = []
        for (let i = 10; i > 0; i--) {
            groupActivity.push({
                name: `群${i}`,
                value: i * 100,
            })
        }
        const uls = []
        for (let i = 1; i < 11; i++) {
            uls.push(
                <div style={{ marginBottom: '15px', width: '100%' }}>
                    <label className={styles.span} style={{ backgroundColor: i === 1 ? '#0050b3' : i === 2 ? '#096dd9' : i === 3 ? '#1890ff' : 'ddd', color: (i === 3 || i === 1 || i === 2) ? '#fff' : '#000' }}>
                        {i}
                    </label>
                    <label className={styles.span_content}>
                        2020-{i}-{i}
                    </label>
                    <label style={{ float: 'right' }}>828373</label>
                </div>

            )
        }

        const menu = (
            <Menu onClick={this.handleMenuClick.bind(this)}>
                <Menu.Item key="1" >
                    前5名
              </Menu.Item>
                <Menu.Item key="2">
                    前10名
              </Menu.Item>
                <Menu.Item key="3">
                    前15名
              </Menu.Item><Menu.Item key="4">
                    前20名
              </Menu.Item>
            </Menu>
        );
        const { nowDate, createGroupWithSpace, createGroupWithSpaceSortList, lastMonthDate, registerUserWithSpace, registerUserWithSpaceSortList, totalUserCount, totalGroupCount, registerUserCount, createdGroupCount, } = this.state
        return (
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <label style={{ marginRight: '10px' }}>请选择日期</label>

                    <DatePicker locale={locale} onChange={this.onChange.bind(this)} defaultValue={moment(nowDate, dateFormat)} format={dateFormat} />
                </div>

                <div style={{ display: 'flex' }}>
                    <div className={styles.card}>
                        <span>当日注册人数</span>
                        <p className={styles.card_num}>{registerUserCount}</p>
                    </div>
                    <div className={styles.card}>
                        <span>当日新建群</span>
                        <p className={styles.card_num}>{createdGroupCount}</p>
                    </div>
                    <div className={styles.card}>
                        <span>当前总用户</span>
                        <p className={styles.card_num}>{totalUserCount}</p>
                    </div>
                    <div className={styles.card}>
                        <span>当前总群数</span>
                        <p className={styles.card_num}>{totalGroupCount}</p>
                    </div>
                </div>

                <div className={styles.card} style={{ marginTop: '20px', width: '100%', }}>
                    <div style={{ display: 'inline-flex' }}>
                        <label className={styles.card_title}>注册用户统计</label>
                        <div style={{ position: 'absolute', right: 0, marginRight: '20px' }}>
                            <label style={{ color: 'black', fontSize: '16px', marginRight: '10px' }}>按月筛选</label>
                            <RangePicker locale={locale} defaultValue={[moment(lastMonthDate, dateFormat), moment(nowDate, dateFormat)]} onChange={this.onRegisterUserDate.bind(this)} />
                        </div>
                    </div>
                    <div className={styles.view_line}></div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <div style={{ width: '66.67%' }}>
                            <Line data={registerUserWithSpace} color='#5B8FF9' width={650} height={400} xField='time' yField='value' meta={{ time: { alias: '日期' }, value: { alias: '注册数量' }, }} />
                        </div>
                        <div style={{ width: '30%', marginLeft: '50px' }}>
                            <h4 style={{ marginBottom: '10px' }}>注册量排名</h4>
                            <div>
                                {registerUserWithSpaceSortList}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.card} style={{ marginTop: '20px', width: '100%', }}>
                    <div style={{ display: 'inline-flex' }}>
                        <label className={styles.card_title}>新建群数量统计</label>
                        <div style={{ position: 'absolute', right: 0, marginRight: '20px' }}>
                            <label style={{ color: 'black', fontSize: '16px', marginRight: '10px' }}>按月筛选</label>
                            <RangePicker locale={locale} defaultValue={[moment(lastMonthDate, dateFormat), moment(nowDate, dateFormat)]} onChange={this.onCreateGroupChnageDate.bind(this)} />
                        </div>
                    </div>
                    <div className={styles.view_line}></div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <div style={{ width: '66.67%' }}>
                            <Column maxColumnWidth={20} color='#52c41a' data={createGroupWithSpace} xField='time' yField='value' meta={{ time: { alias: '日期' }, value: { alias: '创建数量' }, }} width={650} height={400} />
                        </div>
                        <div style={{ width: '30%', marginLeft: '50px' }}>
                            <h4 style={{ marginBottom: '10px' }}>新建群排名</h4>
                            <div>
                                {createGroupWithSpaceSortList}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={styles.card} style={{ marginTop: '20px', width: '100%', }}>
                    <div style={{ display: 'inline-flex' }}>
                        <label className={styles.card_title}>用户活跃度统计</label>
                        <div style={{ position: 'absolute', right: 0, marginRight: '20px' }}>
                            <label style={{ color: 'black', fontSize: '16px', marginRight: '10px' }}>筛选</label>
                            <Dropdown overlay={menu}>
                                <Button style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    选择区间 <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={styles.view_line}></div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <div style={{ width: '66.67%' }}>
                            <Area data={userActivity} xField='name' yField='value' meta={
                                {
                                    name: { alias: '用户名' },
                                    value: { alias: '活跃度' },
                                }
                            } areaStyle={function areaStyle() {
                                return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' };
                            }} />
                        </div>
                        <div style={{ width: '30%', marginLeft: '50px' }}>
                            <h4 style={{ marginBottom: '10px' }}>用户活跃度排名</h4>
                            <div>
                                {uls}
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className={styles.card} style={{ marginTop: '20px', width: '100%', }}>
                    <div style={{ display: 'inline-flex' }}>
                        <label className={styles.card_title}>群活跃度统计</label>
                        <div style={{ position: 'absolute', right: 0, marginRight: '20px' }}>
                            <label style={{ color: 'black', fontSize: '16px', marginRight: '10px' }}>筛选</label>
                            <Dropdown overlay={menu}>
                                <Button style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    选择区间 <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={styles.view_line}></div>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                        <div style={{ width: '66.67%' }}>
                            <Column data={groupActivity} xField='name' yField='value' meta={
                                {
                                    name: { alias: '群名称' },
                                    value: { alias: '活跃度' },
                                }
                            }
                                label={
                                    {
                                        position: 'middle',
                                        style: {
                                            fill: '#FFFFFF',
                                            opacity: 0.6,
                                        },
                                    }
                                } color='#13c2c2' />
                        </div>
                        <div style={{ width: '30%', marginLeft: '50px' }}>
                            <h4 style={{ marginBottom: '10px' }}>用户活跃度排名</h4>
                            <div>
                                {uls}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Welcome