export class LiMUtils {
    // 获取当前日期
    static getNowDateYYYYMMDD(): string {
        var myDate = new Date()
        var month = myDate.getMonth() + 1
        var day = myDate.getDate()
        return myDate.getFullYear() + "-" + (month < 10 ? ('0' + month) : month) + "-" + (day < 10 ? '0' + day : day)
    }
    // 获取当前时间的上一个月日期
    static getLastMonthDateYYYYMMDD(): string {
        var lastMonthToday = new Date(
            new Date().getTime() - 30 * 24 * 60 * 60 * 1000
        )
        var lastMonthYear = lastMonthToday.getFullYear()
        var lastMonth = lastMonthToday.getMonth() + 1

        var lastMonthDay =
            lastMonthToday.getDate() < 10
                ? "0" + lastMonthToday.getDate()
                : lastMonthToday.getDate()
        var lastMonthKsrq = lastMonthYear + "-" + (lastMonth < 10 ? ('0' + lastMonth) : lastMonth) + "-" + lastMonthDay
        return lastMonthKsrq
    }
    static getNowTime(): number {
        return new Date().getTime()
    }
}