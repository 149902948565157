import { Breadcrumb, Button, Divider, Input, message } from "antd";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import { LIMApp } from "../../modules";
import creatHistory from 'history/createHashHistory'
const history = creatHistory()


interface SendMsgState {
    content: string
    loading: boolean
    senderName: string
    sender: string
    receiveder: string
    receivederName: string
    receivederChannelType: number
}

class SendMsg extends Component<any, SendMsgState>{
    constructor(props: any) {
        super(props)
        this.state = {
            content: '',
            loading: false,
            receivederChannelType: this.props.location.state.receivederChannelType,
            sender: this.props.location.state.sender,
            senderName: this.props.location.state.senderName,
            receiveder: this.props.location.state.receiveder,
            receivederName: this.props.location.state.receivederName,
        }
    }
    onChange(v: any) {
        this.setState({ content: v.target.value })
    }
    sendMsg() {
        if (!this.state.content || this.state.content === '') {
            message.warn('请输入消息内容')
            return
        }
        const then = this
        then.setState({
            loading: true
        })
        axios.post('manager/message/send', {
            "sender": then.state.sender,
            "sender_name": then.state.senderName,
            "received_channel_id": then.state.receiveder,
            "received_channel_type": then.state.receivederChannelType,
            "content": then.state.content,
        }).then(function (resp) {
            then.setState({
                loading: false,
                content: ''
            })
            message.success('发送成功')
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    goBack() {
        history.goBack()
    }
    render() {
        const { content, receivederChannelType, senderName, sender, receivederName, receiveder, loading } = this.state
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a>上一页</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        发送消息
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div style={{ padding: '10px', width: '30%', marginTop: '20px', backgroundColor: '#fff' }}>
                    <Divider plain>接受者信息</Divider>
                    <div >
                        <label htmlFor="">{receivederChannelType === LIMApp.shared().channelGroup ? '接受群ID：' : '接受者ID：'}</label>
                        <label style={{ marginLeft: '10px' }}>{receiveder}</label>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <label htmlFor="">{receivederChannelType === LIMApp.shared().channelGroup ? '接受群名称：' : '接受者名称：'}</label>
                        <label style={{ marginLeft: '10px' }}>{receivederName}</label>
                    </div>

                    <Divider plain>发送者信息</Divider>
                    <div>
                        <label htmlFor="">发送者ID：</label>
                        <label style={{ marginLeft: '10px' }}>{sender}</label>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <label htmlFor="">发送者名字：</label>
                        <label style={{ marginLeft: '10px' }}>{senderName}</label>
                    </div>

                </div>


                <Input.TextArea style={{ width: '30%', marginTop: '20px', minHeight: '100px', }} placeholder='请输入内容' value={content} onChange={this.onChange.bind(this)} />
                <br />
                <Button loading={loading} onClick={this.sendMsg.bind(this)} type='primary' style={{ width: '30%', marginTop: '20px' }}>发送</Button>
            </div>
        )
    }
}

export default SendMsg