
export class LIMMethodOptions{
    sort: number | undefined
    category: String | undefined
    constructor(category:string='',sort:number=0){
        this.sort = sort
        this.category = category
    }
}

export class LIMMethod{
    sid!: String
    sort: number | undefined
    category: String | undefined
    handler!: Function
}

export class LIMMethodManager{
    private methods: Map<String,Array<LIMMethod>> = new Map()
    private methodMap: Map<String,LIMMethod> = new Map()

    setMethod(sid:String,handler:Function,opt:LIMMethodOptions){
       const method = new LIMMethod()
       method.sid = sid
       method.handler = handler
       if(opt){
        method.category = opt.category
        method.sort = opt.sort
       }
       this.methodMap.set(sid,method)
       if(method.category && method.category !== ''){
           let array = this.methods.get(method.category)
           if(!array){
               array = new Array<LIMMethod>()
           }
           array.push(method)
           this.methods.set(method.category,array)
       }
    }

    invoke(sid:String): any{
      let m = this.methodMap.get(sid)
      if(m) {
       return m.handler(sid)
      }
      return null
    }

    invokes(category:String,param:any): Array<any> {
       const array = this.methods.get(category)
       const results = new Array<any>()
        if(array){
            for(const m of array){
                console.log(m)
                results.push(m.handler(param))
            }
        }
        return results
    }

}