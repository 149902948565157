import { Component } from "react";
import { withRouter } from "react-router";

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Button, Input, message, Pagination, Table, Image, Spin } from 'antd'

import styles from '../style.module.css'
import axios from "axios";
import { LIMApp } from "../../modules";
import confirm from "antd/lib/modal/confirm";

const { Search } = Input


interface DisableUserListState {
    page: number
    pageSize: number,
    totalCount: number,
    spinning: boolean,
    data: any[]
}
class DisableUserList extends Component<any, DisableUserListState>{
    constructor(props: any) {
        super(props)
        this.state = {
            page: parseInt(window.location.hash.slice(1), 0) || 1,
            spinning: true,
            pageSize: 15,
            totalCount: 0,
            data: []
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/user/disablelist?page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(function (resp) {
            const list = []
            for (let i = 0; i < resp.data.list.length; i++) {
                let user = resp.data.list[i]
                let avatar = LIMApp.shared().getAvatarUrl(user.uid, LIMApp.shared().channelPersonal)
                list.push({
                    name: user.name,
                    mobile: user.phone,
                    avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                    shortNum: user.short_no,
                    uid: user.uid,
                    registerDate: user.register_time,
                    closureTime: user.closure_time,
                    sex: user.sex === 1 ? '男' : '女',
                })
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count
            })
        }).catch(function (error) {
            then.setState({
                spinning: false
            })
            message.error(error.msg)
        })
    }
    onSearch(v: any) {
        console.log('搜索内容：', v)
    }
    showTotal(v: any) {
        return `共${v}条数据`
    }
    onPageChange(v: any) {
        this.setState({
            page: v
        }, () => {
            this.getData()
        })
    }
    render() {
        const { data, page, totalCount, pageSize, spinning } = this.state
        const columns = [
            {
                title: '用户名',
                dataIndex: 'name',
                fixed: true,
                width: 50,
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
                width: 50,
                fixed: true,
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                width: 30,
            },
            {
                title: '用户ID',
                dataIndex: 'uid',
                width: 80,
            },
            {
                title: '唠唠号',
                dataIndex: 'shortNum',
                width: 50,
            },

            {
                title: '性别',
                dataIndex: 'sex',
                width: 30,
            },
            {
                title: '注册时间',
                dataIndex: 'registerDate',
                width: 100,
            },
            {
                title: '封禁日期',
                dataIndex: 'closureTime',
                width: 100,
            },

            {
                title: '操作',
                key: 'operation',
                width: 100,
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (user: any) => <div>
                    <Button size="small" type="primary" style={{ marginLeft: '10px' }} onClick={() => {
                        const then = this
                        confirm({
                            title: '解禁用户',
                            content: `确定解禁用户‘${user.name}’吗？`,
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                                axios.put(`manager/user/liftban/${user.uid}/1`).then(function (resp) {
                                    for (let i = 0; i < then.state.data.length; i++) {
                                        if (then.state.data[i].uid === user.uid) {
                                            then.state.data.splice(i, 1)
                                            break
                                        }
                                    }
                                    console.log(then.state.data)
                                    then.setState({
                                        data: [...then.state.data]
                                    })
                                    message.success('操作成功')
                                }).catch(function (error) {
                                    message.error(error.msg)
                                })
                            },
                            onCancel() {

                            }
                        })
                    }}>解禁</Button>
                </div>
            },
        ];

        return (

            <div>
                <p>封禁用户列表</p>
                <Search style={{ width: 'auto', marginBottom: '20px' }} placeholder="请输入用户名" onSearch={this.onSearch.bind(this)} enterButton />
                <Spin spinning={spinning} >
                    <Table pagination={false} columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} />
                    <Pagination defaultPageSize={pageSize} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onShowSizeChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            window.location.hash = page + ''
                            this.getData()
                        })
                    }} onChange={this.onPageChange.bind(this)} />
                </Spin>
            </div>
        )
    }
}

export default DisableUserList