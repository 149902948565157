import { Component } from "react";
import { withRouter } from "react-router";
import axios from 'axios'
import { EllipsisOutlined, ExclamationCircleOutlined, InfoCircleOutlined, SolutionOutlined, HistoryOutlined, TeamOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Input, message, Menu, Space, Table, Image, Pagination, Dropdown, Spin } from 'antd'

import styles from '../style.module.css'
import { LIMApp } from "../../modules";
import confirm from "antd/lib/modal/confirm";

const { Search } = Input

interface ListState {
    data: any[]
    totalCount: number
    page: number
    pageSize: number | undefined
    searchKey: string
    spinning: boolean
}

class GroupList extends Component<any, ListState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            spinning: true,
            totalCount: 0,
            page: parseInt(window.location.hash.slice(1), 0) || 1,
            pageSize: 15,
            searchKey: '',
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true
        })
        let then = this
        axios.get(`manager/group/list?keyword=${this.state.searchKey}&page_size=${this.state.pageSize}&page_index=${this.state.page}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.list.length; i++) {
                let group = resp.data.list[i]
                let avatar = LIMApp.shared().getAvatarUrl(group.group_no, LIMApp.shared().channelGroup)
                list.push(
                    {
                        name: group.name,
                        groupNo: group.group_no,
                        avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                        memberCount: group.member_count,
                        status: group.status,
                        statusStr: group.status === 1 ? '正常' : '封禁中',
                        adminName: group.create_name,
                        adminID: group.creator,
                        registerDate: group.create_at,
                        forbidden: group.forbidden,
                    }
                )
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count
            })
        }).catch(function (error) {
            then.setState({
                spinning: false
            })
            message.error(error.msg)
        })
    }
    onSearch(v: any) {
        if (this.state.searchKey !== v) {
            this.setState({
                searchKey: v,
            }, () => {
                this.getData()
            })
        } else {
            if (v === '') {
                message.warn('请输入内容')
            }
        }
    }

    showTotal(total: any) {
        return `一共${total}条数据`
    }
    getMenu(group: any): any {
        const menu = (
            <Menu onClick={(v: any) => {
                let key = v.key
                switch (key) {
                    case 'groupMember':
                        this.props.history.push('/groupmembers', { 'groupNo': group.groupNo, 'name': group.name })
                        break
                    case 'msgRecord': {
                        this.props.history.push("/record", {
                            channelID: group.groupNo,
                            channelType: LIMApp.shared().channelGroup,
                            channelName: group.name
                        });
                    }
                        break
                    case 'blacklist':
                        this.props.history.push("/groupblacklist", { 'groupNo': group.groupNo, 'name': group.name })
                        break
                    case 'forbidden':
                        {
                            const then = this
                            let content = ''
                            if (group.forbidden === 0) {
                                content = `确定要对群组‘${group.name}’禁言吗？`
                            } else {
                                content = `确定要对群组‘${group.name}’解除禁言吗？`
                            }
                            confirm({
                                title: '提示',
                                content: content,
                                icon: <ExclamationCircleOutlined />,
                                okText: '确定',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    axios.put(`manager/groups/${group.groupNo}/forbidden/${group.forbidden === 1 ? 0 : 1}`).then(function (resp) {
                                        for (let i = 0; i < then.state.data.length; i++) {
                                            if (then.state.data[i].groupNo === group.groupNo) {
                                                then.state.data[i].forbidden = then.state.data[i].forbidden === 1 ? 0 : 1
                                                break
                                            }
                                        }
                                        then.setState({
                                            data: [...then.state.data]
                                        })
                                        message.success('操作成功')
                                    }).catch(function (error) {
                                        message.error(error.msg)
                                    })
                                }
                            })

                        }
                        break
                    case 'ban':
                        {
                            let then = this
                            let content = ''
                            if (group.status === 1) {
                                content = `确定要对群组‘${group.name}’进行封禁吗？`
                            } else {
                                content = `确定要解禁群组‘${group.name}’吗？`
                            }
                            confirm({
                                title: '提示',
                                content: content,
                                icon: <ExclamationCircleOutlined />,
                                okText: '确定',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    axios.put(`manager/group/liftban/${group.groupNo}/${group.status === 1 ? 0 : 1}`).then(function (resp) {
                                        for (let i = 0; i < then.state.data.length; i++) {
                                            if (then.state.data[i].groupNo === group.groupNo) {
                                                then.state.data[i].status = then.state.data[i].status === 1 ? 0 : 1
                                                then.state.data[i].statusStr = then.state.data[i].status === 1 ? '正常' : '封禁中'
                                                break
                                            }
                                        }
                                        then.setState({
                                            data: [...then.state.data]
                                        })
                                        message.success('操作成功')
                                    }).catch(function (error) {
                                        message.error(error.msg)
                                    })
                                }
                            })
                        }
                        break
                }
            }} >
                <Menu.Item key="groupMember" icon={<TeamOutlined />}>
                    群成员
                </Menu.Item>
                <Menu.Item key="msgRecord" icon={<HistoryOutlined />}>
                    聊天记录
                </Menu.Item>
                <Menu.Item key="blacklist" icon={<SolutionOutlined />}>
                    黑名单成员
                </Menu.Item>
                <Menu.Item key="forbidden" icon={<StopOutlined />}>
                    {group.forbidden === 1 ? '禁言中' : '禁言'}
                </Menu.Item>
                <Menu.Item key="ban" icon={<InfoCircleOutlined />}>
                    {group.status === 1 ? '封禁' : '解禁'}
                </Menu.Item>
            </Menu>
        );
        return menu
    }
    render() {
        const { data, page, totalCount, pageSize, spinning } = this.state

        const columns = [
            {
                title: '群名称',
                dataIndex: 'name',
                fixed: true,
                width: 100,
            },
            {
                title: '群编号',
                dataIndex: 'groupNo',
                width: 100,
            },
            {
                title: '群头像',
                dataIndex: 'avatar',
                width: 50,
            },
            {
                title: '群状态',
                dataIndex: 'statusStr',
                width: 50,
            },
            {
                title: '群人数',
                dataIndex: 'memberCount',
                width: 50
            },
            {
                title: '群主名称',
                dataIndex: 'adminName',
                width: 100,
            },
            {
                title: '群主ID',
                dataIndex: 'adminID',
                width: 100,
            },
            {
                title: '创建时间',
                dataIndex: 'registerDate',
                width: 100,
            },
            {
                title: '操作',
                key: 'operation',
                width: 90,
                fixed: 'right' as 'right',
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (group: any) => <div>
                    <Button size='small' type='primary' onClick={() => {
                        this.props.history.push("/sendmsg", {
                            receivederChannelType: LIMApp.shared().channelGroup,
                            receiveder: group.groupNo,
                            receivederName: group.name,
                            sender: group.adminID,
                            senderName: group.adminName,
                        });
                    }}>发消息</Button>
                    <Dropdown overlay={this.getMenu(group)}>
                        <Button size="small" style={{ marginLeft: '10px' }}>
                            <Space>
                                更多
                                <EllipsisOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </div>
            },
        ];

        return (
            <div>
                <p>群列表</p>
                <Search style={{ width: 'auto', marginBottom: '20px' }} placeholder="请输入群名称/群编号" onSearch={this.onSearch.bind(this)} enterButton />
                <Spin spinning={spinning}>

                    <Table sticky={{ offsetHeader: 0, offsetScroll: -100 }} columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
                    <Pagination style={{ float: 'right', marginTop: '20px' }} defaultPageSize={pageSize} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            window.location.hash = page + ''
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default GroupList