
import { LIMApp } from '../../modules'
import baseStyles from '../style.module.css'
import { Image } from 'antd';
import "@lottiefiles/lottie-player/dist/tgs-player";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "tgs-player": any;
        }
    }
}
export class MsgUtils {

    static text = 1
    static image = 2
    static gif = 3
    static voice = 4
    static video = 5
    static location = 6
    static card = 7
    static file = 8
    static redpackege = 9
    static transfer = 10
    static multipleForward = 11
    static sticker = 12
    static emojiSticker = 13

    static getMsgContentType(type: number): string {
        switch (type) {
            case this.text:
                return '[文本]'
            case this.image:
                return '[图片]'
            case this.gif:
                return '[GIF]'
            case this.voice:
                return '[语音]'
            case this.video:
                return '[视频]'
            case this.location:
                return '[位置]'
            case this.card:
                return '[名片]'
            case this.file:
                return '[文件]'
            case this.redpackege:
                return '[红包]'
            case this.transfer:
                return '[转帐]'
            case this.multipleForward:
                return '[合并转发]'
            case this.sticker:
                return '[动态表情]'
            case this.emojiSticker:
                return '[矢量emoji]'
        }
        if (type >= 1000 && type <= 2000) {
            return '[系统消息]'
        }
        return '[未知消息类型]'
    }

    static getAvatarView(uid: string): any {
        if (uid === "")
            return ''
        else {
            let senderAvatar = LIMApp.shared().getAvatarUrl(uid, LIMApp.shared().channelPersonal)
            return (
                <div>
                    <Image alt='' className={baseStyles.avatar} src={senderAvatar} />
                </div>
            )
        }
    }

    static getDisplayContent(payload: any): any {
        let type = payload['type'] as number
        switch (type) {
            case this.text:
                return payload['content']
            case this.gif:
            case this.image: {
                let path = payload['url']
                let url = LIMApp.shared().getShowURL(path)
                return (
                    <div>
                        <Image
                            width={100}
                            src={url}
                        />
                    </div>
                )
            }
            case this.voice:
                return '[语音]'
            case this.video: {
                let path = payload['url']
                let url = LIMApp.shared().getShowURL(path)
                return (
                    <div>
                        <video controls src={url} width='250' height='200'>
                        </video>
                    </div>
                )
            }
            case this.file:
                return '文件'
            case this.location: {
                let title = payload['title']
                let address = payload['address']
                return (
                    <div>
                        <b>{title}</b>
                        <p>{address}</p>
                    </div>
                )
            }
            case this.card:
                {
                    let uid = payload['uid']
                    let name = payload['name']
                    return (
                        <div>
                            <label htmlFor="">名片UID：{uid}</label>
                            <p>用户名：{name}</p>
                        </div>
                    )
                }
            case this.redpackege: {
                let redpacket_no = payload['redpacket_no']
                let blessing = payload['blessing']
                return (
                    <div>
                        <p>红包编号：{redpacket_no}</p>
                        <p>祝福语：{blessing}</p>
                    </div>
                )
            }
            case this.transfer: {
                let amount = payload['amount']
                let remark = payload['remark']
                return (
                    <div>
                        <b>金额：¥{this.showAmount(amount)}</b>
                        <p>备注：{remark}</p>
                    </div>
                )
            }
            case this.emojiSticker:
            case this.sticker: {
                let path = payload['url']
                let url = LIMApp.shared().getShowURL(path)
                return (
                    <div>
                        <tgs-player style={{ width: "auto", height: "100px" }} autoplay loop mode="normal" src={url}>
                        </tgs-player>
                    </div>
                )
            }
        }
        if (type >= 1000 && type <= 2000) {
            let content = payload['content'] as string
            let extra = payload['extra']
            if (extra != null && extra.length > 0) {
                for (var i = 0; i < extra.length; i++) {
                    content = content.replace(`{${i}}`, extra[i]['name'])
                }
            }
            if (type === 1014) {
                content = '[截屏消息]'
            }
            return content
        }
        return JSON.stringify(payload)
    }

    static showAmount(amount: number) {
        var str = (amount / 100).toFixed(2) + '';
        var intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ',');
        var dot = str.substring(str.length, str.indexOf("."))
        var ret = intSum + dot;
        return ret;
    }
}

