import { Button, Table, message, Pagination } from "antd";
import confirm from "antd/lib/modal/confirm";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import { LIMApp } from "../../modules";

import styles from './style.module.css'
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 6, span: 16 },
}
interface SytemRobotMenuState {
    data: any[]
}

class SytemRobotMenu extends Component<any, SytemRobotMenuState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: []
        }
        this.initData()
    }

    initData() {
        const then = this
        axios.get(`manager/robot/menus?robot_id=${LIMApp.shared().sytemUserUID}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.length; i++) {
                list.push(
                    {
                        robot_id: resp.data[i].robot_id,
                        type: resp.data[i].type,
                        cmd: resp.data[i].cmd,
                        remark: resp.data[i].remark
                    }
                )
            }
            then.setState({
                data: list
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }

    render() {
        const { data } = this.state
        const columns = [
            {
                title: '命令',
                dataIndex: 'cmd',
                width: 150,
            },
            {
                title: '说明',
                dataIndex: 'remark',
                width: 350,
            },
            {
                title: '类型',
                dataIndex: 'type',
                width: 50,
            },
            {
                title: '操作',
                key: 'operation',
                fixed: true,
                width: 50,
                render: (menu: any) => {
                    return <div>
                        <Button size="small" type='primary' onClick={() => {
                            let content = `确定要删除命令 '${menu.cmd}' 吗?`
                            confirm({
                                title: '提示',
                                content: content,
                                okText: '确定',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    axios.delete(`manager/robot/${menu.robot_id}/${menu.cmd}`).then(function (resp) {
                                        // todo
                                    }).catch(function (error) {
                                        message.error(error.msg)
                                    })
                                }
                            })
                        }}>删除</Button>
                    </div>
                }
            }
        ]
        return (
            <div>
                <p>系统机器人菜单列表</p>
                <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 'auto' }} pagination={false} />
            </div>
        )
    }
}

export default SytemRobotMenu