import { LIMApp, LIMRoute } from "../../modules";
import { LIMContext, LIMModule } from "../../modules/base/module";
import sendmsg from "./sendmsg";
import sendmsglist from "./sendmsglist";
import prohibitwrods from "./prohibitwords";
import record from "./record";
import recordpersonal from "./recordpersonal";

export class MsgModule implements LIMModule {
    getModuleID() {
        return 'msg'
    }

    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/prohibitwords', prohibitwrods))
        LIMApp.shared().registerRoute(new LIMRoute('/sendmsg', sendmsg))
        LIMApp.shared().registerRoute(new LIMRoute('/sendmsglist', sendmsglist))
        LIMApp.shared().registerRoute(new LIMRoute('/record', record))
        LIMApp.shared().registerRoute(new LIMRoute('/recordpersonal', recordpersonal))
    }
}