import redpacket from ".";
import { LIMApp, LIMRoute } from "../../modules";
import { LIMContext, LIMModule } from "../../modules/base/module";
import detail from "./detail";


export class RedPacketModule implements LIMModule{
    getModuleID(){
        return 'redpacket'
    }
    initModule(ctx:LIMContext){
        LIMApp.shared().registerRoute(new LIMRoute('/redpacketlist',redpacket))
        LIMApp.shared().registerRoute(new LIMRoute('/redpacketdetail',detail))
    }
}