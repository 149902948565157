
export class LIMContext{

}

export class LIMModuleManager{
    private moduleMap: Map<String, LIMModule> = new Map()
    registerModule(m: LIMModule) {
        this.moduleMap.set(m.getModuleID(), m)
    }
    getAllModule(): Array<LIMModule> {
        const modules = Array<LIMModule>()
        this.moduleMap.forEach((value, key) => {
            modules.push(value)
        })
        return modules
    }
} 

export interface LIMModule{
    getModuleID(): String
    initModule(ctx: LIMContext): void 
}