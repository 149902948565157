import { LIMApp, LIMRoute } from "../../modules";
import { LIMContext, LIMModule } from "../../modules/base/module";
import blacklist from "./blacklist";
import disablelist from "./disablelist";
import groupmembers from "./groupmembers";
import list from "./list";


export class GroupModule implements LIMModule {
    getModuleID() {
        return 'group'
    }
    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/grouplist', list))
        LIMApp.shared().registerRoute(new LIMRoute('/groupdisablelist', disablelist))
        LIMApp.shared().registerRoute(new LIMRoute('/groupmembers', groupmembers))
        LIMApp.shared().registerRoute(new LIMRoute('/groupblacklist', blacklist))
    }

}