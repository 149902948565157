import { Button, message, Pagination, Table, Image, Spin } from "antd";
import confirm from "antd/lib/modal/confirm";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import { LIMApp } from "../../modules";
import baseStyles from '../style.module.css'
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface DisableGroupState {
    data: any[]
    totalCount: number
    page: number
    spinning: boolean
}

class DisableGroupList extends Component<any, DisableGroupState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            totalCount: 0,
            page: 1,
            spinning: true
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/group/disablelist?page_size=10&page_index=${this.state.page}`).then(function (resp) {
            const list = []
            for (let i = 0, size = resp.data.list.length; i < size; i++) {
                const group = resp.data.list[i]
                let avatar = LIMApp.shared().getAvatarUrl(group.group_no, LIMApp.shared().channelGroup)
                list.push({
                    name: group.name,
                    groupNo: group.group_no,
                    creator: group.creator,
                    createName: group.create_name,
                    createAt: group.create_at,
                    memberCount: group.member_count,
                    avatar: <Image alt='' className={baseStyles.avatar} src={avatar} />,
                })
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count,
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }
    onPageChange(v: any) {
        this.setState({
            page: v
        }, () => {
            this.getData()
        })
    }
    showTotal(total: any) {
        return `一共${total}条数据`
    }
    render() {
        const { data, page, totalCount, spinning } = this.state
        const columns = [
            {
                title: '群名称',
                dataIndex: 'name',
                fixed: true,
                width: 100,
            },
            {
                title: '群编号',
                dataIndex: 'groupNo',
                width: 70,
            },
            {
                title: '群头像',
                dataIndex: 'avatar',
                width: 50,
            },
            {
                title: '人数',
                dataIndex: 'memberCount',
                width: 50
            },
            {
                title: '群主名称',
                dataIndex: 'createName',
                width: 100,
            },
            {
                title: '创建时间',
                dataIndex: 'createAt',
                width: 100,
            },
            {
                title: '操作',
                key: 'operation',
                width: 50,
                fixed: true,
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (group: any) => <div>
                    <Button size="small" type="primary" danger style={{ marginLeft: '10px' }} onClick={() => {
                        let then = this
                        confirm({
                            title: '解禁群组',
                            icon: <ExclamationCircleOutlined />,
                            content: `确定解禁群组 ‘${group.name}’吗？`,
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                axios.put(`manager/group/liftban/${group.groupNo}/1`).then(function (resp) {
                                    for (let i = 0; i < then.state.data.length; i++) {
                                        if (then.state.data[i].groupNo === group.groupNo) {
                                            then.state.data.splice(i, 1)
                                            break
                                        }
                                    }
                                    then.setState({
                                        data: [...then.state.data]
                                    })
                                }).catch(function (error) {
                                    message.error(error)
                                })
                            },
                            onCancel() {

                            },
                        })
                    }}>解禁</Button>
                </div>
            },
        ]
        return (
            <div>
                <p>封禁群列表</p>
                <Spin spinning={spinning}>
                    <Table sticky={{ offsetHeader: 0, offsetScroll: -100 }} columns={columns} dataSource={data} scroll={{ x: 'auto', y: 'auto' }} pagination={false} />
                    <Pagination style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={this.onPageChange.bind(this)} />
                </Spin>

            </div>
        )
    }
}

export default DisableGroupList