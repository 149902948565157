import { Component } from "react"
import { withRouter } from 'react-router'
import { Button, Modal, Input, Table, message, Pagination, Spin, Space, Menu, Dropdown, Image } from 'antd'
import { ExclamationCircleOutlined, EllipsisOutlined, TeamOutlined, SolutionOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios'
import styles from '../style.module.css'
import { LIMApp } from "../../modules";
import TextArea from "antd/lib/input/TextArea";

const { Search } = Input
const { confirm } = Modal

interface UserListState {
    data: any[]
    page: number
    pageSize: number | undefined
    totalCount: number
    searchKey: string
    visible: boolean
    spinning: boolean
    user: { name: '', uid: '' }
}

class UserList extends Component<any, UserListState>{
    constructor(props: any) {
        super(props)
        this.state = {
            spinning: true,
            user: { name: '', uid: '' },
            data: [],
            page: parseInt(window.location.hash.slice(1), 0) || 1,
            visible: false,
            pageSize: 15,
            totalCount: 0,
            searchKey: ''
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/user/list?keyword=${this.state.searchKey}&page_size=${this.state.pageSize}&page_index=${this.state.page}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.list.length; i++) {
                let user = resp.data.list[i]
                let avatar = LIMApp.shared().getAvatarUrl(user.uid, LIMApp.shared().channelPersonal)
                let state
                if (user.is_destroy === 1) {
                    state = <b style={{ color: '#ff0000' }}>已注销</b>
                } else {
                    if (user.status === 0) {
                        state = <b style={{ color: '#ff0000' }}>已封禁</b>
                    } else {
                        state = <label>正常</label>
                    }
                }
                let device
                if (user.device_name !== '') {
                    device = <div>{user.device_name}({user.device_model})</div>
                }
                list.push(
                    {
                        name: user.name,
                        mobile: user.phone,
                        avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                        shortNum: user.short_no,
                        uid: user.uid,
                        onlineStatus: user.online === 1 ? '在线' : '离线',
                        device: device,
                        registerDate: user.register_time,
                        lastOnlineTime: user.last_login_time,
                        sex: user.sex === 1 ? '男' : '女',
                        status: state
                    }
                )
            }
            then.setState({
                spinning: false,
                data: list,
                totalCount: resp.data.count
            })
        }).catch(function (err) {
            then.setState({
                spinning: false,
            })
            message.error(err.msg)
        })
    }
    onSearch(v: any) {
        if (this.state.searchKey !== v) {
            this.setState({
                searchKey: v,
            }, () => {
                this.getData()
            })
        } else {
            if (v === '') {
                message.warn('请输入内容')
            }
        }
    }

    showTotal(total: any) {
        return `共${total}条数据`
    }
    sendMsgDialogCancel() {
        this.setState({
            visible: false
        }, () => {
            this.getData()
        })
    }
    sendMsgDialogOk() {

    }
    disableUser() {
        confirm({
            title: '禁用用户',
            icon: <ExclamationCircleOutlined />,
            content: '确定要禁用用户吗',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    getMenus(user: any): any {
        const menu = (
            <Menu onClick={(v: any) => {
                let key = v.key
                switch (key) {
                    case 'friendList':
                        this.props.history.push("/friends", { uid: user.uid, username: user.name })
                        break
                    case 'blackList':
                        this.props.history.push('/userblacklist', { uid: user.uid, username: user.name })
                        break
                    case 'ban': {
                        let title = '禁用用户'
                        let content = `确定要封禁用户 '${user.name}' 吗?`
                        if (user.status === 0) {
                            title = '解禁用户'
                        }
                        const then = this
                        confirm({
                            title: title,
                            icon: <ExclamationCircleOutlined />,
                            content: content,
                            okText: '确定',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                axios.put(`manager/user/liftban/${user.uid}/${user.status === 1 ? 0 : 1}`).then(function (resp) {
                                    user.status = user.status === 1 ? 0 : 1
                                    const list = then.state.data
                                    for (let i = 0; i < list.length; i++) {
                                        if (list[i].uid === user.uid) {
                                            list[i].status = user.status
                                            break
                                        }
                                    }
                                    then.setState({
                                        data: list
                                    })
                                    message.success('操作成功')
                                }).catch(function (error) {
                                    message.error(error.msg)
                                })
                            },
                            onCancel() {

                            },
                        });
                    }
                        break
                }
            }}>
                <Menu.Item key='friendList' icon={<TeamOutlined />}>
                    好友列表
                </Menu.Item>
                <Menu.Item key='blackList' icon={<SolutionOutlined />}>
                    黑名单列表
                </Menu.Item>
                <Menu.Item key='ban' icon={<InfoCircleOutlined />}>
                    {user.status === 1 ? '封禁' : '解禁'}
                </Menu.Item>
            </Menu>
        )
        return menu
    }
    render() {
        const { data, totalCount, page, visible, user, pageSize, spinning } = this.state
        const avatarURL = LIMApp.shared().getAvatarUrl(user.uid, LIMApp.shared().channelPersonal)

        const columns = [
            {
                title: '用户名',
                dataIndex: 'name',
                fixed: true,
                width: 50,
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
                width: 100,
                fixed: true,
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                width: 50,
            },
            {
                title: '用户ID',
                dataIndex: 'uid',
                width: 100,
            },
            {
                title: '用户状态',
                dataIndex: 'status',
                width: 50,
            },
            {
                title: '唠唠号',
                dataIndex: 'shortNum',
                width: 100,
            },
            {
                title: '性别',
                dataIndex: 'sex',
                width: 50,
            },

            {
                title: '注册时间',
                dataIndex: 'registerDate',
                width: 100,
            },
            {
                title: '登录设备',
                dataIndex: 'device',
                width: 100,
            },
            {
                title: '在线状态',
                dataIndex: 'onlineStatus',
                width: 50,
            },
            {
                title: '最后离线时间',
                dataIndex: 'lastOnlineTime',
                width: 100,
            },
            {
                title: '操作',
                key: 'operation',
                width: 100,
                fixed: 'right' as 'right',
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (user: any) => {
                    return <div>
                        <Button type="primary" size="small" onClick={() => {
                            this.props.history.push("/sendmsg", {
                                receivederChannelType: LIMApp.shared().channelPersonal,
                                receiveder: user.uid,
                                receivederName: user.name,
                                sender: LIMApp.shared().loginInfo.uid,
                                senderName: LIMApp.shared().loginInfo.name,
                            });
                        }} >发消息</Button>
                        <Dropdown overlay={this.getMenus(user)}>
                            <Button size="small" style={{ marginLeft: '10px' }}>
                                <Space>
                                    更多
                                    <EllipsisOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </div>
                }
            },
        ];

        return (
            <div>
                <p>用户列表</p>
                <Search style={{ width: 'auto', marginBottom: '20px' }} placeholder="uid/手机号/用户名" onSearch={this.onSearch.bind(this)} enterButton />
                <Spin spinning={spinning} >
                    <Table columns={columns} dataSource={data} scroll={{ x: 2000, y: 'auto' }} pagination={false} />
                    <Pagination style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} defaultPageSize={pageSize} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            window.location.hash = page + ''
                            this.getData()
                        })
                    }} />
                </Spin>
                <Modal centered title='发消息' visible={visible} onCancel={this.sendMsgDialogCancel.bind(this)} onOk={this.sendMsgDialogOk.bind(this)} okText='发送' cancelText='取消'>
                    <p>接收者信息</p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img alt='' className={styles.avatar} src={avatarURL}></img>
                        <div style={{ marginLeft: '10px' }}>
                            <label>用户名：{user.name}</label>
                            <br />
                            <label>用户ID：{user.uid}</label>
                        </div>
                    </div>
                    <TextArea style={{ marginTop: '10px' }} rows={5} placeholder="请输入内容"></TextArea>
                </Modal>
            </div>
        )
    }
}

export default UserList