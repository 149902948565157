import { Table, Breadcrumb, message } from "antd";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import creatHistory from 'history/createHashHistory'
const history = creatHistory()

interface RedPacketDetailState {
    data: any[]
    redpacketNo: string
}

class RedPacketDetail extends Component<any, RedPacketDetailState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            redpacketNo: this.props.location.state.redpacketNo,
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        const then = this
        axios.get(`manager/redpackets/${this.state.redpacketNo}`).then(function (resp) {
            const list = []
            for (let i = 0; i < resp.data.length; i++) {
                const model = resp.data[i]
                list.push({
                    username: model.name,
                    uid: model.receiver,
                    amount: (model.amount / 100).toFixed(2),
                    time: model.received_at,
                    isLuck: model.is_luck === 1 ? '是' : '否',
                })
            }
            then.setState({
                data: list,
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    goBack() {
        history.goBack()
    }
    render() {
        const { data } = this.state
        const columns = [
            {
                title: '领红包用户昵称',
                dataIndex: 'username',
                fixed: true,
                width: 50
            },
            {
                title: '领红包用户ID',
                dataIndex: 'uid',
                width: 100
            },
            {
                title: '领取金额(元)',
                dataIndex: 'amount',
                width: 50
            },
            {
                title: '最佳手气',
                dataIndex: 'isLuck',
                width: 30
            },
            {
                title: '领取时间',
                dataIndex: 'time',
                width: 100
            }
        ]

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a>红包记录</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        红包领取详情
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
            </div>
        )
    }
}

export default RedPacketDetail