import { Button, Modal, Input, Pagination, Radio, Table, Upload, message } from "antd";
import { Component } from "react";
import { withRouter } from "react-router";
import { UploadOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { LIMApp } from "../../modules";

interface AppUpdateState {
    page: number
    size: number
    data: any[]
    visible: boolean
    totalCount: number
    os: string
    desc: string
    version: string
    force: boolean
    uploadURL: string
    formData: FormData
    filePath: string
    progress: string
    fileName: string
}

class AppUpdate extends Component<any, AppUpdateState>{
    constructor(props: any) {
        super(props)
        this.state = {
            page: 1,
            filePath: '',
            fileName: '',
            formData: new FormData(),
            desc: '',
            version: '',
            force: false,
            uploadURL: '',
            os: 'android',
            visible: false,
            totalCount: 0,
            progress: '',
            size: 10,
            data: []
        }
    }
    componentDidMount() {
        this.getData()
    }
    getUploadURL() {
        console.log('上传地址请求')
        let then = this
        axios.get(`file/upload?type=common&path=/android/${this.state.fileName}.apk`).then(function (resp) {
            then.setState({
                uploadURL: resp.data.url,
            })
            // 上传文件
            then.uploadApk()
        }).catch(function (err) {
            message.error(err.msg)
        })
    }
    uploadApk() {
        let then = this
        axios.post(this.state.uploadURL, this.state.formData, {
            headers: {
                'token': LIMApp.shared().loginInfo.token
            },
            onUploadProgress(v: any) {
                let pg = v.loaded / v.total
                let progress = ''
                progress = ((pg * 100).toFixed(2)) + "%"
                then.setState({
                    progress: progress
                })
            }
        }).then(function (resp) {
            then.setState({
                filePath: resp.data.path,
                progress:'已上传'
            })
        }).then(function (err) {

        })
    }
    getData() {
        let then = this
        axios.get(`common/appversion/list?page_size=${this.state.size}&page_index=${this.state.page}`).then(function (resp) {
            let list = []
            for (let i = 0, size = resp.data.list.length; i < size; i++) {
                var appVersion = resp.data.list[i]
                list.push({
                    'os': appVersion.os,
                    'version': appVersion.app_version,
                    'desc': appVersion.update_desc,
                    'force': appVersion.is_force === 1 ? '是' : '否',
                    'time': appVersion.created_at,
                })
            }
            then.setState({
                data: list,
                totalCount: resp.data.count,
            })
        }).then(function (err) {

        })
    }
    showDialog() {
        this.setState({
            visible: !this.state.visible,
        })
    }
    showTotal(v: any) {
        return `共${v}条数据`
    }
    onPageChange(v: any) {
        this.setState({
            page: v
        }, () => {
            this.getData()
        })
    }
    submit(v: any) {
        if (this.state.os === 'android' && this.state.filePath === '') {
            message.warn('请上传apk文件')
            return
        }
        if (this.state.desc === '') {
            message.warn('请输入升级说明')
            return
        }
        if (this.state.version === '') {
            message.warn('请输入版本号')
            return
        }
        let then = this
        axios.post('common/appversion', {
            'app_version': then.state.version,
            'os': then.state.os,
            'is_force': then.state.force === true ? 1 : false,
            'update_desc': then.state.desc,
            'download_url': then.state.filePath,
        }).then(function (resp) {
            then.setState({
                visible: !then.state.visible,
                page: 1,
            }, () => {
                then.getData()
            })
            message.success('新建成功')
        }).then(function (error) {
        })

    }
    changeOS(v: any) {
        this.setState({
            os: v.target.value,
        })
    }
    changeForce(v: any) {
        this.setState({
            force: v.target.value,
        })
    }
    cancelClick(v: any) {
        this.setState({
            visible: !this.state.visible,
        })
    }
    desChange(v: any) {
        this.setState({
            desc: v.target.value,
        })
    }
    versionChange(v: any) {
        this.setState({
            version: v.target.value,
        })
    }
    render() {
        const { page, totalCount, data, visible, os, force, desc, progress } = this.state
        const columns = []
        columns.push(
            {
                title: '升级平台',
                dataIndex: 'os',
                fixed: true,
                width: 100
            },
            {
                title: '升级版本号',
                dataIndex: 'version',
                with: 50
            },
            {
                title: '是否强制更新',
                dataIndex: 'force',
                with: 50
            },
            {
                title: '升级说明',
                dataIndex: 'desc',
                with: 150
            },
            {
                title: '发布时间',
                dataIndex: 'time',
                with: 100
            }
        )
        return (
            <div>
                <div style={{ marginBottom: '20px' }}>
                    <label>APP升级管理</label>
                    <Button onClick={this.showDialog.bind(this)} type='primary' style={{ marginLeft: '20px' }}>新建版本</Button>
                </div>
                <Modal centered={true} title='新建APP版本' visible={visible} okText='提交' cancelText='取消' onCancel={this.cancelClick.bind(this)} onOk={this.submit.bind(this)}>
                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ marginRight: '10px' }}>升级平台</label>
                        <Radio.Group defaultValue={os} onChange={this.changeOS.bind(this)}>
                            <Radio value='android'>Android </Radio>
                            <Radio value='ios'>IOS </Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ marginRight: '10px' }}>升级操作</label>
                        <Radio.Group defaultValue={force} onChange={this.changeForce.bind(this)}>
                            <Radio value={true}>强制更新</Radio>
                            <Radio value={false}>非强制更新 </Radio>
                        </Radio.Group>
                    </div>

                    <div>
                        <label style={{ marginRight: '10px' }}>安装包</label>

                        <Upload maxCount={1} beforeUpload={(file, fileList) => {
                            const formData = new FormData();
                            formData.append('file', file)
                            this.setState({
                                formData: formData,
                                fileName: file.name
                            }, () => {
                                this.getUploadURL()
                            })
                            return false
                        }}>
                            <Button disabled={os === 'ios' ? true : false} icon={<UploadOutlined />}>点击上传APK</Button>
                            <label style={{ marginLeft: '10px' }}>{progress}</label>
                        </Upload>

                    </div>

                    <div style={{ marginTop: '20px' }} >
                        <label htmlFor="" style={{ marginRight: '10px' }}>版本号</label>
                        <Input onChange={this.versionChange.bind(this)} style={{ width: '30%' }} placeholder="请输入版本号" />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <label >升级说明</label>
                        <TextArea onChange={this.desChange.bind(this)} value={desc} rows={5} style={{ marginTop: '10px', marginBottom: '20px' }} placeholder='请输入升级说明如：1、新增朋友圈功能 2、修复已知问题' />
                    </div>
                </Modal>
                <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
                <Pagination style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={this.onPageChange.bind(this)} />
            </div>
        )
    }
}

export default AppUpdate