import { Breadcrumb, Button, message, Pagination, Table, Image, Spin } from "antd";
import Search from "antd/lib/input/Search";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import { LIMApp } from "../../modules";
import styles from '../style.module.css'
import creatHistory from 'history/createHashHistory'
const history = creatHistory()

interface GroupMembersState {
    data: any[]
    page: number
    pageSize: number | undefined
    spinning: boolean
    totalCount: number
    groupNo: string
    groupName: string
    keyword: string
}

class GroupMembers extends Component<any, GroupMembersState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            spinning: true,
            totalCount: 0,
            page: parseInt(window.location.hash.slice(1), 0) || 1,
            pageSize: 15,
            keyword: '',
            groupNo: this.props.location.state.groupNo,
            groupName: this.props.location.state.name,
        }
    }
    componentDidMount() {
        this.getData()
    }
    goBack() {
        history.goBack()
    }
    getData() {
        this.setState({
            spinning: true
        })
        let then = this
        axios.get(`manager/groups/${this.state.groupNo}/members?keyword=${this.state.keyword}&page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(function (resp) {
            let list = []
            for (let i = 0, size = resp.data.list.length; i < size; i++) {
                let member = resp.data.list[i]
                let role
                if (member.role === 1) {
                    role = <label style={{ color: '#ff4d4f' }}>群主</label>
                } else if (member.role === 2) {
                    role = <label style={{ color: '#1E90FF' }}>管理员</label>
                } else {
                    role = <label>成员</label>
                }
                let avatar = LIMApp.shared().getAvatarUrl(member.uid, LIMApp.shared().channelPersonal)
                list.push({
                    name: member.name,
                    remark: member.remark,
                    uid: member.uid,
                    role: role,
                    avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                    joinDate: member.created_at,
                })
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }
    onSearch(v: any) {
        if (this.state.keyword !== v) {
            this.setState({
                keyword: v,
            }, () => {
                this.getData()
            })
        } else {
            if (v === '') {
                message.warn('请输入内容')
            }
        }
    }
    showTotal(total: any) {
        return `一共${total}条数据`
    }
    render() {
        const { data, groupName, page, totalCount, pageSize, spinning } = this.state
        const columns = [
            {
                title: '用户昵称',
                dataIndex: 'name',
                fixed: true,
                width: 50,
            },
            {
                title: '用户头像',
                dataIndex: 'avatar',
                width: 30,
            },
            {
                title: '群内备注',
                dataIndex: 'remark',
                width: 50,
            },
            {
                title: '用户ID',
                dataIndex: 'uid',
                width: 50,
            },

            {
                title: '成员角色',
                dataIndex: 'role',
                width: 20,
            },

            {
                title: '入群时间',
                dataIndex: 'joinDate',
                width: 50,
            },
            {
                title: '操作',
                key: 'operation',
                width: 100,
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: () => <div>
                    <Button size='small' type='primary'>发消息</Button>
                    <Button size="small" type="primary" danger style={{ marginLeft: '10px' }}>移除</Button>
                </div>
            },
        ];

        return (
            <div className={styles.body}>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a >群列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        群组‘{groupName}’的 群成员
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Search style={{ width: 'auto', marginBottom: '20px', marginTop: '10px' }} placeholder="请输入成员昵称" onSearch={this.onSearch.bind(this)} enterButton />
                <Spin spinning={spinning}>


                    <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
                    <Pagination defaultPageSize={pageSize} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            window.location.hash = page + ''
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default GroupMembers