import { LIMApp, LIMRoute } from '../../modules'
import { LIMContext, LIMModule } from '../../modules/base/module'
import adduser from './adduser'
import blacklist from './blacklist'
import disablelist from './disablelist'
import friends from './friends'
import userlist from './userlist'

export class UserModule implements LIMModule {
    getModuleID() {
        return 'user'
    }
    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/userlist', userlist))
        LIMApp.shared().registerRoute(new LIMRoute('/adduser',adduser))
        LIMApp.shared().registerRoute(new LIMRoute('/disablelist',disablelist))
        LIMApp.shared().registerRoute(new LIMRoute('/friends',friends))
        LIMApp.shared().registerRoute(new LIMRoute('/userblacklist',blacklist))
    }
}