import { LIMApp, LIMRoute } from "../../modules";
import { LIMContext, LIMModule } from "../../modules/base/module";
import updatepwd from "./updatepwd";
import systemrobotmenu from "./systemrobotmenu"
import currencysetting from "./currencysetting"

export class SettingModule implements LIMModule {
    getModuleID() {
        return 'setting'
    }

    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/updatepwd', updatepwd))
        LIMApp.shared().registerRoute(new LIMRoute('/systemrobotmenu',systemrobotmenu))
        LIMApp.shared().registerRoute(new LIMRoute('/currencysetting',currencysetting))
    }
}