import { Button, Dropdown, Menu, Table, Image, Pagination, message, Spin } from "antd";
import { Component } from "react";
import axios from 'axios'
import { withRouter } from "react-router";
import {
    TeamOutlined,
    DownOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { LIMApp } from "../../modules"

interface ReportState {
    channelType: number
    page: number
    size: number | undefined
    spinning: boolean
    data: any[]
    totalCount: number
}
class ReportList extends Component<any, ReportState>{
    constructor(props: any) {
        super(props)
        this.state = {
            channelType: 1,
            page: 1,
            spinning: true,
            totalCount: 0,
            size: 10,
            data: []
        }
    }
    componentDidMount() {
        this.getData()
    }
    handleMenuClick(e: any) {
        this.setState({
            channelType: e.key,
            page: 1
        }, () => {
            this.getData()
        })
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`/manager/report/list?page_index=${this.state.page}&page_size=${this.state.size}&channel_type=${this.state.channelType}`, {
        })
            .then(function (resp) {
                const list = []
                for (let i = 0; i < resp.data.list.length; i++) {
                    const report = resp.data.list[i]
                    const imgs = []
                    if (report.imgs.length > 0) {
                        for (let j = 0; j < report.imgs.length; j++) {
                            let url = LIMApp.shared().config.apiURL + report.imgs[i]
                            imgs.push(
                                <div>
                                    <Image width={35}
                                        height={35}
                                        src={url}
                                    />
                                </div>
                            )
                        }
                    }
                    let avatar = LIMApp.shared().getAvatarUrl(report.channel_id, report.channel_type)

                    list.push(
                        {
                            username: report.name,
                            userID: report.uid,
                            reportChannelID: report.channel_id,
                            reportChannelName: report.channel_name,
                            reportChannelAvatar: <img alt='' style={{ width: '30px', height: '30px', borderWidth: '0.5px', borderColor: '#ccc', borderStyle: 'solid', borderRadius: '15px' }} src={avatar} />,
                            time: report.create_at,
                            reason: report.category_name,
                            remark: report.remark,
                            imgs: <div><Image.PreviewGroup>{imgs}</Image.PreviewGroup></div>
                        }
                    )
                }

                then.setState({
                    totalCount: resp.data.count,
                    data: list,
                    spinning: false
                })
            })
            .catch(function (error) {
                then.setState({
                    spinning: false
                })
                message.error(error.msg)
            })
    }
    showTotal(v: any) {
        return `共${v}条数据`
    }
    render() {
        const { channelType, page, totalCount, data, spinning, size } = this.state
        const columns = []
        columns.push(
            {
                title: '举报者名称',
                dataIndex: 'username',
                fixed: true,
                width: 100
            },
            {
                title: '举报者ID',
                dataIndex: 'userID',
                width: 100
            },
            {
                title: this.state.channelType === 1 ? '被举报用户名称' : '被举报群名称',
                dataIndex: 'reportChannelName',
                width: 100
            },
            {
                title: this.state.channelType === 1 ? '被举报用户头像' : '被举报群头像',
                dataIndex: 'reportChannelAvatar',
                width: 100
            },
            {
                title: this.state.channelType === 1 ? '被举报用户ID' : '被举报群ID',
                dataIndex: 'reportChannelID',
                width: 100
            }, {
            title: '举报图片',
            dataIndex: 'imgs',
            width: 100
        },
            {
                title: '举报原因',
                dataIndex: 'reason',
                width: 100
            },
            {
                title: '举报说明',
                dataIndex: 'remark',
                width: 100
            },
            {
                title: '举报时间',
                dataIndex: 'time',
                width: 150
            }
        )
        const menu = (
            <Menu onClick={this.handleMenuClick.bind(this)}>
                <Menu.Item key="1" icon={<UserOutlined />}>
                    被举报的用户
                </Menu.Item>
                <Menu.Item key="2" icon={<TeamOutlined />}>
                    被举报的群聊
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
                <label>举报管理</label>
                <br />
                <Dropdown overlay={menu}>
                    <Button style={{ marginBottom: '10px', marginTop: '10px' }}>
                        {channelType === 1 ? '举报的用户' : '举报的群聊'} <DownOutlined />
                    </Button>
                </Dropdown>
                <Spin spinning={spinning}>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
                    <Pagination showSizeChanger={true} defaultPageSize={size} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            size: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default ReportList