import { Breadcrumb, Button, Image, message, Spin, Table, Modal } from "antd";
import { Component } from "react";
import TextArea from "antd/lib/input/TextArea";
import { withRouter } from "react-router";
import axios from 'axios'
import styles from '../style.module.css'
import { LIMApp } from "../../modules";
import creatHistory from 'history/createHashHistory'
import { uuidv4 } from "@antv/xflow-core";
const history = creatHistory()
interface FriendsState {
    data: any[]
    uid: string
    username: string
    spinning: boolean
    selectedFriends: any[]
    selectedRowKeys: React.Key[]
    visible: boolean
    content: string
}

class Friends extends Component<any, FriendsState>{
    constructor(props: any) {
        super(props)
        console.log(this.props.location.state.uid)
        this.state = {
            data: [],
            spinning: true,
            uid: this.props.location.state.uid,
            username: this.props.location.state.username,
            visible: false,
            content: '',
            selectedFriends: [],
            selectedRowKeys: [],
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        let then = this
        axios.get(`manager/user/friends?uid=${this.state.uid}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.length; i++) {
                let user = resp.data[i]
                let avatar = LIMApp.shared().getAvatarUrl(user.uid, LIMApp.shared().channelPersonal)
                list.push(
                    {
                        username: user.name,
                        uid: user.uid,
                        name: user.name,
                        remark: user.remark,
                        avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                        time: user.relationship_time,
                    }
                )
            }
            then.setState({
                data: list,
                spinning: false,
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }
    goBack() {
        history.goBack()
    }

    sendMsgDialogOk() {
        if (this.state.content === '') {
            message.warn('请输入内容')
            return
        }
        var uids = []
        var friends = this.state.selectedFriends
        for (var i = 0; i < friends.length; i++) {
            uids.push(friends[i].uid)
        }
        let then = this
        axios.post(`manager/message/sendfriends`, {
            'uid': this.state.uid,
            'to_uids': uids,
            'content': this.state.content,
        }).then(function (resp) {
            then.setState({
                content: '',
                visible: false,
                selectedFriends: [],
                selectedRowKeys: []
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    render() {
        const { data, username, spinning, uid, visible, selectedFriends, content } = this.state
        const columns = [
            {
                title: '好友昵称',
                dataIndex: 'username',
                fixed: 'left' as 'left',
                width: 30
            },
            {
                title: '好友ID',
                dataIndex: 'uid',
                width: 50
            },
            {
                title: '好友头像',
                dataIndex: 'avatar',
                width: 30
            },
            {
                title: '备注',
                dataIndex: 'remark',
                width: 30
            },
            {
                title: '成为好友时间',
                dataIndex: 'time',
                width: 50
            },
            {
                title: '操作',
                key: 'operation',
                width: 100,
                fixed: 'right' as 'right',
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (user: any) => {
                    return <div>
                        <Button type="primary" size="small" onClick={() => {
                            console.log(user)
                            this.props.history.push("/recordpersonal", {
                                name: username,
                                uid: uid,
                                touid: user.uid,
                                toname: user.name
                            });
                        }} >聊天记录</Button>

                    </div>
                }
            },
        ]
        return (
            <div>
                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                            <a >用户列表</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            用户 {username} 的好友
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    {
                        selectedFriends.length > 0 ? <div style={{ marginLeft: '30px' }}>
                            <Button type="primary" onClick={() => {
                                this.setState({
                                    visible: true
                                })
                            }}>发消息</Button>
                            <label style={{ marginLeft: '10px' }}>已选中 {selectedFriends.length} 个好友</label>
                        </div> : ''
                    }
                </div>

                <br />
                <Spin spinning={spinning}>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 'auto' }} pagination={false} />
                </Spin>
                <Modal centered title='发消息' visible={visible} onCancel={() => {
                    this.setState({
                        visible: false
                    })
                }} onOk={this.sendMsgDialogOk.bind(this)} okText='发送' cancelText='取消'>
                    <p>给用户 {username} 选中的 {selectedFriends.length} 位好友发送一条信息</p>
                    <TextArea style={{ marginTop: '10px' }} rows={5} placeholder="请输入内容" value={content} onChange={(v: any) => {
                        this.setState({
                            content: v.target.value
                        })
                    }}></TextArea>
                </Modal>
            </div>
        )
    }
}

export default Friends