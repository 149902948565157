import { Button, Input, Dropdown, message, Menu, Form, Radio, Typography, Space } from "antd";
import { Component } from "react";
import { DownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router";
import styles from './style.module.css'
import axios from "axios"

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 6, span: 16 },
}
interface AddUserState {
    name: string
    mobile: string
    pwd: string
    loading: boolean
    sex: number
    zone: string
}

class AddUser extends Component<any, AddUserState>{
    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            pwd: '',
            mobile: '',
            sex: 1,
            zone: '0086'
        }
    }
    sexChange(v: any) {
        this.setState({
            sex: v.target.value,
        })
    }
    onFinish(value: any) {
        const mobile = value.mobile
        const pwd = value.pwd
        const sex = value.sex
        const username = value.username
        if (mobile.length !== 11) {
            message.error('手机号不合法')
            return
        }
        this.save(username, pwd, mobile, sex)
    }
    save(name: string, pwd: string, mobile: string, sex: number) {
        this.setState({
            loading: true
        })
        let then = this
        axios.post('/manager/user/add', {
            name: name,
            password: pwd,
            phone: mobile,
            zone: "0086",
            sex: sex,
        }).then(function (resp) {
            message.success('操作成功')
            then.setState({
                loading: false,
                pwd: '',
                mobile: '',
                name: '',
            })
        }).catch(function (error) {
                message.error(error.msg)
                then.setState({
                    loading: false
                })
            })
    }

    render() {
        const { loading, sex, name, mobile, pwd, zone } = this.state
        const menu = (
            <Menu >
                <Menu.Item key="1">
                    0086
                </Menu.Item>
            </Menu>
        )
        return (
            <div className={styles.body}>
                <p>添加用户</p>
                <Form style={{ width: '30%' }}
                    onFinish={this.onFinish.bind(this)}
                    {...layout} >
                    <Form.Item label='区号' name='zone' hasFeedback rules={[
                        { required: false, message: '手机号区号' },
                    ]} >
                        <Dropdown overlay={menu}>
                            <Typography.Link>
                                <Space>
                                    {zone}
                                    <DownOutlined />
                                </Space>
                            </Typography.Link>
                        </Dropdown>
                    </Form.Item>
                    <Form.Item label='手机号' name='mobile' hasFeedback rules={[
                        { required: true, message: '手机号不能为空' },
                    ]} >
                        <Input value={mobile} type='telephone' maxLength={11} allowClear={true} style={{ width: '75%', marginLeft: '10px' }} />
                    </Form.Item>
                    <Form.Item label='性别' name='sex' >
                        <Radio.Group onChange={this.sexChange.bind(this)} defaultValue={sex}>
                            <Radio value={1}>男</Radio>
                            <Radio value={0}>女</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label='用户昵称' name='username' hasFeedback rules={[
                        { required: true, message: '昵称不能为空' }
                    ]}>
                        <Input value={name} placeholder='请输入用户昵称' allowClear={true} />
                    </Form.Item>
                    <Form.Item label='登录密码' name='pwd' hasFeedback rules={[
                        { required: true, message: '密码不能为空' }
                    ]}>
                        <Input.Password value={pwd} allowClear={true} placeholder='请输入登录密码' />
                    </Form.Item >
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            保存
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        )
    }
}

export default AddUser