import { LIMApp, LIMRoute } from '..'
import { LIMModule, LIMContext } from '../base/module'
import Login from './src/index'

export class loginModule implements LIMModule {
    getModuleID() {
        return 'login'
    }
    initModule(ctx: LIMContext) {
        LIMApp.shared().registerRoute(new LIMRoute('/login', Login))
    }
}