import { Component } from "react";
import { Breadcrumb, message, Pagination, Image, Table, Spin } from "antd";

import styles from '../style.module.css'
import { withRouter } from "react-router";
import axios from "axios";
import { LIMApp } from "../../modules";
import creatHistory from 'history/createHashHistory'
const history = creatHistory()

interface BlacklistState {
    data: any[]
    page: number
    pageSize: number | undefined
    totalCount: number
    groupNo: string
    groupName: string
    spinning: boolean
}

class Blacklist extends Component<any, BlacklistState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            spinning: true,
            totalCount: 0,
            page: 1,
            pageSize: 15,
            groupNo: this.props.location.state.groupNo,
            groupName: this.props.location.state.name,
        }
    }
    componentDidMount() {
        this.getData()
    }
    goBack() {
        history.goBack()
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/groups/:${this.state.groupNo}/members/blacklist?page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.list.length; i++) {
                let member = resp.data.list[i]
                let role = '成员'
                if (member.role === 1) {
                    role = '群主'
                } else if (member.role === 2) {
                    role = '管理员'
                }
                let avatar = LIMApp.shared().getAvatarUrl(member.uid, LIMApp.shared().channelPersonal)
                list.push({
                    name: member.name,
                    remark: member.remark,
                    uid: member.uid,
                    role: role,
                    avatar: <Image alt='' className={styles.avatar} src={avatar} />,
                    joinDate: member.created_at,
                })
            }
            then.setState({
                data: list,
                spinning: false,
                totalCount: resp.data.count
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }
    showTotal(total: any) {
        return `一共${total}条数据`
    }
    render() {
        const { data, groupName, page, totalCount, pageSize, spinning } = this.state
        const columns = [
            {
                title: '用户昵称',
                dataIndex: 'username',
                fixed: true,
                width: 50
            },
            {
                title: '用户ID',
                dataIndex: 'uid',
                width: 30
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                width: 30
            },
            {
                title: '操作者',
                dataIndex: 'handlerName',
                width: 30
            },
            {
                title: '拉入黑名单时间',
                dataIndex: 'time',
                width: 50
            }
        ]

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a>群列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        群组‘{groupName}’的黑名单成员
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Spin spinning={spinning}>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} />
                    <Pagination defaultPageSize={pageSize} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} showSizeChanger={true} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default Blacklist