import { LIMApp } from "./modules";
import { withRouter, Route, Switch } from 'react-router-dom'
import Layout from './pages/homeLayout'
import App from './pages/app'

App.registers()
App.init()
const Main = withRouter(props => <Layout {...props} />)
// eslint-disable-next-line 
export default () => {
    const routes = LIMApp.shared().getAllRoute()
    const routeList = new Array<any>()
    
    if (routes) {
        for (const route of routes) {
            console.log(route.path)
            routeList.push(<Route key={route.path} exact path={route.path} component={route.component} />)
        }
    }
    return (
        <Main>
            <Switch>
                {
                    routeList
                }
            </Switch>
        </Main>
    );
}