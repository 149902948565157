import { LIMApp, LIMRoute } from "../../modules";
import { LIMModule,LIMContext } from "../../modules/base/module";
import appupdate from "./appupdate";

export class ToolsModule implements LIMModule{
    getModuleID(){
        return 'tools'
    }
    initModule(ctx: LIMContext){
        LIMApp.shared().registerRoute(new LIMRoute('/appupdate', appupdate))
    }
} 