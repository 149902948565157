export class Utils {
    static showTime(time: number): string {
        let tempTime = time
        if (time.toString().length === 10) {
            tempTime = parseInt(time + '') * 1000
        }
        var dateTime = new Date(tempTime)
        var year = dateTime.getFullYear()
        var month = dateTime.getMonth() + 1
        let monthStr = ''
        let dayStr = ''
        let hourStr = ''
        let minuteStr = ''
        let secondStr = ''
        if (month > 0 && month < 10) {
            monthStr = "0" + month
        } else {
            monthStr = month + ''
        }
        var day = dateTime.getDate()
        if (day > 0 && day < 10) {
            dayStr = "0" + day
        } else {
            dayStr = day + ''
        }
        var hour = dateTime.getHours()
        if (hour > 0 && hour < 10) {
            hourStr = "0" + hour
        } else {
            hourStr = hour + ''
        }
        var minute = dateTime.getMinutes()
        if (minute > 0 && minute < 10) {
            minuteStr = "0" + minute;
        } else {
            minuteStr = minute + ''
        }
        var second = dateTime.getSeconds()
        if (second > 0 && second < 10) {
            secondStr = "0" + second;
        } else {
            secondStr = second + ''
        }
        var timeSpanStr = year + '-' + monthStr + '-' + dayStr + ' ' + hourStr + ':' + minuteStr + ':' + secondStr
        return timeSpanStr
    }
}