import { Breadcrumb, Button, message, Pagination, Spin, Table } from "antd"
import axios from "axios"
import { Component, ReactNode } from "react"
import { withRouter } from "react-router"
import { Utils } from "../../utils/common"
import { MsgUtils } from "./msgutils"
import creatHistory from 'history/createHashHistory'
const history = creatHistory()

interface RecordPersonalState {
    data: any[]
    uid: string
    touid: string
    page: number
    name: string
    toname: string
    pageSize: number | undefined
    spinning: boolean
    totalCount: number
}
class RecordPersonal extends Component<any, RecordPersonalState>{

    constructor(props: any) {
        super(props)
        this.state = {
            name: this.props.location.state.name,
            toname: this.props.location.state.toname,
            uid: this.props.location.state.uid,
            touid: this.props.location.state.touid,
            spinning: true,
            page: 1,
            pageSize: 15,
            totalCount: 0,
            data: [],
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        let then = this
        axios.get(`manager/message/recordpersonal?uid=${this.state.uid}&touid=${this.state.touid}&page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(function (resp) {
            let list = []
            if (resp.data !== null && resp.data.list !== null) {
                for (let i = 0; i < resp.data.list.length; i++) {
                    let msg = resp.data.list[i]
                    let showContent: any
                    if (msg.is_encrypt === 1) {
                        showContent = '[加密消息，无法查看]'
                    } else {
                        let content = MsgUtils.getDisplayContent(msg.payload)
                        showContent = content
                        if (msg.edited_at > 0) {
                            showContent = <div>
                                {content}
                                <p style={{ color: '#ff0000' }}>编辑日期：{Utils.showTime(msg.edited_at)}</p>
                            </div>
                        }
                    }
                    list.push({
                        senderName: msg.sender_name,
                        sender: msg.sender,
                        senderAvatar: MsgUtils.getAvatarView(msg.sender),
                        messageID: msg.message_id,
                        displayContent: showContent,
                        isEncrypt: msg.is_encrypt === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                        isDeleted: msg.is_deleted === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                        isRevoke: msg.revoke === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                        time: msg.created_at,
                    })
                }
            }

            then.setState({
                spinning: false,
                totalCount: resp.data['count'],
                data: list
            })
        }).catch(function (error) {
            then.setState({
                spinning: false
            })
        })
    }
    showTotal(total: any) {
        return `一共${total}条数据`
    }
    goBack() {
        history.goBack()
    }
    render() {
        const { data, page, totalCount, pageSize, spinning, name, toname } = this.state
        const columns = [
            {
                title: '消息编号',
                dataIndex: 'messageID',
                fixed: 'left' as 'left',
                width: 13,
            },
            {
                title: '发送者名字',
                dataIndex: 'senderName',
                fixed: 'left' as 'left',
                width: 13,
            },
            {
                title: '发送者ID',
                dataIndex: 'sender',
                width: 20,
            },
            {
                title: '发送者头像',
                dataIndex: 'senderAvatar',
                width: 10,
            },
            {
                title: '消息内容',
                dataIndex: 'displayContent',
                width: 20
            },
            {
                title: '是否加密',
                dataIndex: 'isEncrypt',
                width: 15,
            },
            {
                title: '是否撤回',
                dataIndex: 'isRevoke',
                width: 15,
            },
            {
                title: '是否删除',
                dataIndex: 'isDeleted',
                width: 15,
            },
            {
                title: '发送时间',
                dataIndex: 'time',
                width: 20
            },
            {
                title: '操作',
                key: 'operation',
                width: 10,
                fixed: 'right' as 'right',
                render: (msg: any) =>
                    <div>
                        <Button size="small" type="primary" danger>
                            删除
                        </Button>
                    </div>
            }
        ]
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a >返回上一页</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <label><b> {name}</b> 和 <b>{toname}</b> 的聊天记录</label>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={spinning}>
                    <Table style={{ marginTop: '10px' }} scroll={{ x: 1500, y: 'auto' }} columns={columns} dataSource={data} pagination={false} />
                    <Pagination showSizeChanger={true} style={{ float: 'right', marginTop: '20px' }} defaultPageSize={pageSize} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default RecordPersonal