import { Button, message, Pagination, Spin, Table, Modal, Breadcrumb } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import React, { Component, useState } from "react";
import { withRouter } from "react-router";
import { Utils } from "../../utils/common";
import { MsgUtils } from "./msgutils";
import creatHistory from 'history/createHashHistory'
const history = creatHistory()
const { confirm } = Modal

interface RecordState {
    data: any[],
    channelID: string,
    channelType: number,
    channelName: string,
    page: number,
    pageSize: number | undefined
    totalCount: number
    spinning: boolean
    selectedmsgs: any[]
    selectedRowKeys: React.Key[]
}


class Record extends Component<any, RecordState>{

    constructor(props: any) {
        super(props)
        this.state = {
            channelID: this.props.location.state.channelID,
            channelType: this.props.location.state.channelType,
            channelName: this.props.location.state.channelName,
            spinning: true,
            page: 1,
            pageSize: 15,
            totalCount: 0,
            data: [],
            selectedmsgs: [],
            selectedRowKeys: []
        }
    }
    componentDidMount() {
        this.getData()
    }

    showTotal(total: any) {
        return `一共${total}条数据`
    }
    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/message/record?channel_id=${this.state.channelID}&page_index=${this.state.page}&page_size=${this.state.pageSize}`).then(
            function (resp) {
                let list = []
                for (let i = 0; i < resp.data.list.length; i++) {
                    let msg = resp.data.list[i]
                    let content = MsgUtils.getDisplayContent(msg.payload)
                    let showContent: any = content
                    if (msg.edited_at > 0) {
                        showContent = <div>
                            {content}
                            <p style={{ color: '#ff0000' }}>编辑日期：{Utils.showTime(msg.edited_at)}</p>
                        </div>
                    }
                    list.push({
                        senderName: msg.sender_name,
                        sender: msg.sender,
                        senderAvatar: MsgUtils.getAvatarView(msg.sender),
                        messageID: msg.message_id,
                        messageSeq: msg.message_seq,
                        key: msg.message_id,
                        displayContent: showContent,
                        is_deleted: msg.is_deleted,
                        isDeleted: msg.is_deleted === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                        isRevoke: msg.revoke === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                        time: msg.created_at,
                    })
                }
                then.setState({
                    spinning: false,
                    data: list,
                    totalCount: resp.data['count']
                })
            }

        ).catch(function (err) {
            then.setState({
                spinning: false
            })
            message.error(err.message)
        })
    }
    deleteMsg(msgs: any[]) {
        if (msgs.length === 0) {
            message.warn('请选择消息')
            return
        }
        let list = []
        for (let i = 0; i < msgs.length; i++) {
            list.push({
                'message_id': msgs[i].messageID + '',
                'message_seq': msgs[i].messageSeq
            })
        }
        let then = this
        axios.delete('manager/message', {
            data: {
                'channel_id': this.state.channelID,
                'channel_type': this.state.channelType,
                'list': list
            }
        }).then(function (_) {
            then.getData()
            then.setState({
                selectedRowKeys: [],
                selectedmsgs: []
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    goBack() {
        history.goBack()
    }
    render() {

        const { channelName, data, page, totalCount, pageSize, spinning, selectedmsgs, selectedRowKeys } = this.state
        const columns = [
            {
                title: '消息编号',
                dataIndex: 'messageID',
                fixed: 'left' as 'left',
                width: 13,
            },
            {
                title: '发送者名字',
                dataIndex: 'senderName',
                fixed: 'left' as 'left',
                width: 13,
            },
            {
                title: '发送者ID',
                dataIndex: 'sender',
                width: 20,
            },
            {
                title: '发送者头像',
                dataIndex: 'senderAvatar',
                width: 10,
            },
            {
                title: '消息内容',
                dataIndex: 'displayContent',
                width: 20
            },
            {
                title: '是否撤回',
                dataIndex: 'isRevoke',
                width: 15,
            },
            {
                title: '是否删除',
                dataIndex: 'isDeleted',
                width: 15,
            },
            {
                title: '发送时间',
                dataIndex: 'time',
                width: 20
            },
            {
                title: '操作',
                key: 'operation',
                width: 10,
                fixed: 'right' as 'right',
                render: (msg: any) =>
                    <div>
                        <Button disabled={msg.is_deleted === 1} size="small" type="primary" danger>
                            删除
                        </Button>
                    </div>
            }
        ]

        return (
            <div>
                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                            <a >返回上一页</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <label><b> {channelName}</b>的聊天记录</label>
                        </Breadcrumb.Item>
                    </Breadcrumb>


                    {
                        selectedmsgs.length > 0 ? <div style={{ marginLeft: '30px' }}>
                            <Button danger type="primary" onClick={() => {
                                confirm({
                                    title: '删除消息',
                                    content: '确定要删除选中的消息吗',
                                    icon: <ExclamationCircleOutlined />,
                                    okText: '确定',
                                    cancelText: '取消',
                                    onOk: () => {
                                        this.deleteMsg(selectedmsgs)
                                    }
                                })
                            }}>删除选中消息({selectedmsgs.length})</Button>
                        </div> : ''
                    }
                </div>

                <Spin spinning={spinning}>
                    <Table rowSelection={{
                        type: 'checkbox',
                        columnWidth: '5px',
                        selectedRowKeys: selectedRowKeys,
                        onChange: (selectedRowKeys, list) => {
                            this.setState({
                                selectedRowKeys: selectedRowKeys,
                                selectedmsgs: list
                            })
                        },
                        getCheckboxProps: (msg: any) => ({
                            disabled: msg.is_deleted === 1
                        })
                    }} scroll={{ x: 1500, y: 'auto' }} columns={columns} dataSource={data} pagination={false} />
                    <Pagination showSizeChanger={true} style={{ float: 'right', marginTop: '20px' }} defaultPageSize={pageSize} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>
            </div>
        )
    }
}

export default Record