
import { message, Form, Card, Select, Button, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { Component, ReactNode } from "react";
import { withRouter } from "react-router";

const { Option } = Select;


interface CurrencySettingState {
    welcomeMessage: string
    revokeSecond: number
    newUserJoinSystemGroup: number
    loading: boolean
}

class CurrencySetting extends Component<any, CurrencySettingState>{
    constructor(props: any) {
        super(props)
        this.state = {
            welcomeMessage: '',
            revokeSecond: 120,
            newUserJoinSystemGroup: 1,
            loading: false,
        }
    }
    componentDidMount() {
        this.getData()
    }

    getData() {
        let then = this
        axios.get('manager/common/appconfig').then(function (resp) {
            then.setState({
                welcomeMessage: resp.data.welcome_message,
                revokeSecond: resp.data.revoke_second,
                newUserJoinSystemGroup: resp.data.new_user_join_system_group
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    saveClick() {
        this.setState({
            loading: true
        })
        let then = this
        var revokeSecond = this.state.revokeSecond
        var welcomMessage = this.state.welcomeMessage
        var newUserJoinSystemGroup = this.state.newUserJoinSystemGroup
        axios.post('manager/common/appconfig', {
            'welcome_message': welcomMessage,
            'revoke_second': revokeSecond,
            'new_user_join_system_group': newUserJoinSystemGroup
        }).then(function () {
            then.setState({
                loading: false
            })
            message.success('保存成功')
        }).catch(function (error) {
            message.error(error.msg)
            then.setState({
                loading: false
            })
        })
    }
    render() {
        const { welcomeMessage, loading, revokeSecond, newUserJoinSystemGroup } = this.state
        return (
            <div>
                <label style={{ color: '#999999' }}>消息通用设置</label>
                <div style={{ marginTop: "20px" }}>
                    <Card>
                        <div>
                            <label style={{ marginRight: '5%' }}>用户默认加入群聊：</label>
                            <Radio.Group onChange={(v: any) => {
                                this.setState({
                                    newUserJoinSystemGroup: v.target.value,
                                })
                            }} defaultValue={1} value={newUserJoinSystemGroup}>
                                <Radio value={1}>加入</Radio>
                                <Radio value={0}>不加入</Radio>
                            </Radio.Group>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <label style={{ marginRight: '5%' }}>消息可撤回时长：</label>
                            <Select value={revokeSecond} defaultValue={revokeSecond} onChange={(value) => {
                                this.setState({
                                    revokeSecond: value
                                })
                            }}>
                                <Option value={60}>1分钟</Option>
                                <Option value={120}>2分钟</Option>
                                <Option value={180}>3分钟</Option>
                                <Option value={240}>4分钟</Option>
                                <Option value={300}>5分钟</Option>
                                <Option value={-1}>永久</Option>
                            </Select>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '5%' }}>登录欢迎语：</div>
                            <div style={{ display: 'inline-block', verticalAlign: 'top', width: '60%', marginLeft: '25px' }}>
                                <TextArea maxLength={1000} showCount={true} inputMode="text" rows={5} style={{}} value={welcomeMessage} placeholder='请输入登录欢迎语' onChange={(v: any) => {
                                    this.setState({
                                        welcomeMessage: v.target.value
                                    })
                                }} />
                            </div>
                        </div>
                        <Button loading={loading} type="primary" block style={{ width: '50%', marginTop: '20px' }} onClick={this.saveClick.bind(this)}>保存</Button>
                    </Card>
                </div>
            </div>
        )
    }
}


export default CurrencySetting