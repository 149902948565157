import { Button, message, Table, Pagination, Menu, Dropdown, Input, Spin } from "antd";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";

import {
    DownOutlined,
} from '@ant-design/icons'

interface RedPacketState {
    data: any[]
    page: number
    pageSize: number | undefined
    totalCount: number
    creater: string
    menus: any[]
    loading: boolean
    spinning: boolean
    year: number
}

class RedpacketList extends Component<any, RedPacketState>{
    constructor(props: any) {
        super(props)
        this.state = {
            menus: [],
            page: 1,
            pageSize: 15,
            spinning: true,
            totalCount: 0,
            data: [],
            creater: '',
            loading: false,
            year: new Date().getFullYear(),
        }

    }
    componentDidMount() {
        this.getYears()
        this.getCount()
        this.getData()
    }
    getYears() {
        const then = this
        axios.get('manager/redpacket/years').then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.length; i++) {
                list.push(
                    <Menu.Item key={resp.data[i]}>
                        {resp.data[i]}
                    </Menu.Item>
                )
            }
            then.setState({
                menus: list
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }
    getCount() {
        const then = this
        axios.get(`manager/redpacket/count?creater=${this.state.creater}&year=${this.state.year}`).then(function (resp) {
            then.setState({
                totalCount: resp.data.count
            })
        }).catch(function (error) {
            message.error(error.msg)
        })
    }

    getData() {
        this.setState({
            spinning: true
        })
        const then = this
        axios.get(`manager/redpacket/list?year=${this.state.year}&creater=${this.state.creater}&page_index=${this.state.page}&page_size${this.state.pageSize}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.length; i++) {
                let model = resp.data[i]
                let status = ''
                if (model.status === 3) {
                    status = '已领取完毕'
                } else if (model.status === 4) {
                    status = '已过期'
                } else if (model.status === 2) {
                    status = '进行中'
                } else if (model.status === 1) {
                    status = '已支付'
                }
                list.push({
                    name: model.creater_name,
                    uid: model.creater,
                    redpacketNo: model.redpacket_no,
                    amount: (model.amount / 100).toFixed(2),
                    count: model.num,
                    type: model.type === 1 ? '定额红包' : '随机红包',
                    status: status,
                    blessing: model.blessing,
                    time: model.created_at,
                    channelType: model.scene_type === 1 ? '单聊' : '群聊',
                    channelID: model.scene_id,
                })
            }
            then.setState({
                data: list,
                loading: false,
                spinning: false
            })
        }).catch(function (error) {
            then.setState({
                loading: false,
                spinning: false
            })
            message.error(error.msg)
        })
    }
    onSearch(v: any) {
        this.setState({ creater: v.target.value })
    }
    showTotal(v: any) {
        return `共${v}条数据`
    }

    onchange(v: any) {
        console.log(v)
    }
    handleMenuClick(e: any) {
        this.setState({
            year: e.key
        })
    }
    search() {
        this.setState({
            loading: true,
            page: 1,
        })

        this.getCount()
        this.getData()
    }
    render() {
        const { data, page, totalCount, menus, year, loading, spinning, pageSize } = this.state
        const columns = [
            {
                title: '发送者昵称',
                dataIndex: 'name',
                fixed: true,
                width: 50
            },
            {
                title: '发送者UID',
                dataIndex: 'uid',
                width: 100
            },
            {
                title: '红包编号',
                dataIndex: 'redpacketNo',
                width: 100
            },
            {
                title: '红包金额(元)',
                dataIndex: 'amount',
                width: 50,
                // sorter: {
                //     compare: (a: any, b: any) => a.amount - b.amount,
                //     multiple: 3,
                // },
            },
            {
                title: '红包个数',
                dataIndex: 'count',
                width: 50
            },
            {
                title: '红包类型',
                dataIndex: 'type',
                width: 100
            },
            {
                title: '状态',
                dataIndex: 'status',
                width: 50
            },
            {
                title: '祝福语',
                dataIndex: 'blessing',
                width: 100
            },
            {
                title: '发送时间',
                dataIndex: 'time',
                width: 100
            },
            {
                title: '聊天类型',
                dataIndex: 'channelType',
                width: 50
            },
            {
                title: '聊天id',
                dataIndex: 'channelID',
                width: 100
            },
            {
                title: '操作',
                key: 'operation',
                width: 30,
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (model: any) => <div>
                    <Button size="small" type="primary" style={{ marginLeft: '10px', marginRight: '10px' }} onClick={() => {
                        this.props.history.push("/redpacketdetail", { redpacketNo: model.redpacketNo });
                    }}>领取详情</Button>
                </div>
            },
        ]
        const menu = (
            <Menu onClick={this.handleMenuClick.bind(this)}>
                {menus}
            </Menu>
        );
        return (
            <div>
                <label htmlFor="">红包记录</label>
                <br />
                <div style={{ marginTop: '10px' }}>
                    <Input style={{ width: 'auto', marginBottom: '20px' }} placeholder="用户uid" onChange={this.onSearch.bind(this)} />
                    <Dropdown overlay={menu}>
                        <Button style={{ marginLeft: '10px' }}>
                            {year} <DownOutlined />
                        </Button>
                    </Dropdown>
                    <Button style={{ marginLeft: '10px' }} type='primary' loading={loading} onClick={this.search.bind(this)}>查询</Button>
                </div>

                <Spin spinning={spinning}>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1500, y: 'auto' }} pagination={false} onChange={this.onchange.bind(this)} />
                    <Pagination showSizeChanger={true} defaultPageSize={pageSize} style={{ float: 'right', marginTop: '20px' }} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            page: page,
                            pageSize: size
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>

            </div>
        )
    }
}

export default RedpacketList