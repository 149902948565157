import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";

import styles from './style.module.css'
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 6, span: 16 },
}
interface UpdatePwdState {
    loading: boolean
    oldpwd: string
    newpwd: string
    surepwd: string
}

class UpdatePwd extends Component<any, UpdatePwdState>{
    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            oldpwd: '',
            newpwd: '',
            surepwd: ''
        }
    }
    onFinish(value: any) {
        const oldpwd = value.oldpwd
        const newpwd = value.newpwd
        this.save(oldpwd, newpwd)
    }
    onFinishFailed(v: any) {
        console.log(v)
    }
    save(oldPwd: string, newPwd: string) {
        this.setState({
            loading: true
        })
        const then = this
        axios.post('manager/user/updatepassword', {
            'password': oldPwd,
            'new_password': newPwd,
        }).then(function (resp) {
            then.setState({
                oldpwd: '',
                newpwd: '',
                surepwd: '',
                loading: false,
            })
            message.success('修改成功')
        }).catch(function (error) {
            message.error(error.msg)
            then.setState({
                loading: false,
            })
        })
    }
    render() {
        const { loading, surepwd, newpwd, oldpwd } = this.state
        return (
            <div className={styles.body}>
                <p style={{ marginBottom: '20px' }}>修改登录密码</p>
                <Form style={{ width: '30%' }} {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish.bind(this)}
                    onFinishFailed={this.onFinishFailed.bind(this)}>
                    <Form.Item hasFeedback label='旧密码' name='oldpwd' rules={[
                        { required: true, message: '请输入旧密码' }
                    ]}>
                        <Input.Password value={oldpwd} />
                    </Form.Item>
                    <Form.Item label='新密码' name='newpwd' hasFeedback rules={[
                        { required: true, message: '请输入新密码' },
                    ]}>
                        <Input.Password value={newpwd} />
                    </Form.Item>
                    <Form.Item hasFeedback label='确认密码' name='surepwd' dependencies={['newpwd']} rules={[
                        { required: true, message: '请输入确认密码' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newpwd') === value) {
                                    return Promise.resolve()
                                }
                                if (value.length < 6) {
                                    return Promise.reject(new Error('密码长度必须大于6位!'))
                                }
                                return Promise.reject(new Error('新密码和确认密码不一致!'))
                            },
                        }),
                    ]}>
                        <Input.Password value={surepwd} />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            提交
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        )
    }
}

export default UpdatePwd