import { Component } from "react";
import { Breadcrumb, message, Spin, Table } from "antd";

import { LIMApp } from "../../modules";
import axios from 'axios'
import styles from './style.module.css'
import { withRouter } from "react-router";
import creatHistory from 'history/createHashHistory'
const history = creatHistory()
interface BlacklistState {
    data: any[]
    uid: string
    spinning: boolean
    username: string
}


class Blacklist extends Component<any, BlacklistState>{
    constructor(props: any) {
        super(props)
        this.state = {
            data: [],
            spinning: true,
            uid: this.props.location.state.uid,
            username: this.props.location.state.username
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        let then = this
        axios.get(`manager/user/blacklist?uid=${this.state.uid}`).then(function (resp) {
            let list = []
            for (let i = 0; i < resp.data.length; i++) {
                let user = resp.data[i]
                let avatar = LIMApp.shared().getAvatarUrl(user.uid, LIMApp.shared().channelPersonal)
                list.push(
                    {
                        username: user.name,
                        uid: user.uid,
                        avatar: <div><img alt='' className={styles.avatar} src={avatar} /></div>,
                        time: user.create_at,
                    }
                )
            }
            then.setState({
                data: list,
                spinning: false,
            })
        }).catch(function (error) {
            then.setState({
                spinning: false,
            })
            message.error(error.msg)
        })
    }
    goBack() {
        history.goBack()
    }
    render() {
        const columns = [
            {
                title: '好友昵称',
                dataIndex: 'username',
                fixed: true,
                width: 30
            },
            {
                title: '好友ID',
                dataIndex: 'uid',
                width: 50
            },
            {
                title: '好友头像',
                dataIndex: 'avatar',
                width: 30
            },
            {
                title: '备注',
                dataIndex: 'remark',
                width: 30
            },
            {
                title: '拉入黑名单时间',
                dataIndex: 'time',
                width: 50
            }
        ]
        const { data, username, spinning } = this.state

        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={this.goBack.bind(this)}>
                        <a>用户列表</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        用户 {username} 的黑名单
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <Spin spinning={spinning}>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1000, y: 'auto' }} pagination={false} />
                </Spin>
            </div>
        )
    }
}
export default Blacklist