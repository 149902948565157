import { Button, message, Table, Modal, Pagination, Input, Spin } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import { Component } from "react";
import { withRouter } from "react-router";
import TextArea from "antd/lib/input/TextArea";
import confirm from "antd/lib/modal/confirm";

interface ProhibitWordsState {
    page: number,
    pageSize: number | undefined,
    showAddDilaog: boolean,
    totalCount: number,
    total: number,
    searchKey: string,
    content: string,
    spinning: boolean,
    data: any[]
}
class ProhibitWords extends Component<any, ProhibitWordsState>{
    constructor(props: any) {
        super(props)
        this.state = {
            page: 1,
            spinning: true,
            totalCount: 0,
            total: 0,
            searchKey: '',
            showAddDilaog: false,
            pageSize: 15,
            content: '',
            data: [],
        }
    }
    componentDidMount() {
        this.getData()
    }
    getData() {
        this.setState({
            spinning: true,
        })
        let then = this
        axios.get(`manager/message/prohibit_words?page_size=${this.state.pageSize}&page_index=${this.state.page}&search_key=${this.state.searchKey}`).then(function (resp) {
            let list = resp.data.list
            let resps = []
            for (let i = 0; i < list.length; i++) {
                let words = list[i]
                resps.push({
                    id: words.id,
                    word: words.content,
                    is_deleted: words.is_deleted,
                    isDeleted: words.is_deleted === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否',
                    createDate: words.created_at,
                })
            }
            then.setState({
                spinning: false,
                totalCount: resp.data.count,
                data: resps
            })
        }).catch(function (err) {
            then.setState({
                spinning: false
            })
            message.error(err.msg)
        })
    }
    add() {
        if (this.state.content === '') {
            message.warn('请输入内容')
            return
        }
        let then = this
        axios.post(`manager/message/prohibit_words?content=${this.state.content}`).then(function (resp) {
            message.success('添加成功')
            then.setState({
                content: '',
                showAddDilaog: false,
                page: 1,
            }, () => {
                then.getData()
            })
        }).catch(function (err) {
            message.error(err.msg)
        })
    }
    showAddDialog() {
        this.setState({
            showAddDilaog: true
        })
    }
    showTotal(total: any) {
        return `一共${total}条数据`
    }
    render() {
        const { page, pageSize, showAddDilaog, totalCount, data, content, spinning } = this.state
        const columns = [
            {
                title: '违禁词',
                dataIndex: 'word',
                width: 30,
                fixed: 'left' as 'left',
            },
            {
                title: '是否删除',
                dataIndex: 'isDeleted',
                width: 20,
            },
            {
                title: '创建时间',
                dataIndex: 'createDate',
                width: 30,
            },
            {
                title: '操作',
                key: 'operation',
                width: 30,
                sticky: { offsetScroll: 4, offsetHeader: 4 },
                render: (word: any) => {
                    console.log(word.is_deleted)
                    return <div>
                        <Button size='small' type='primary' danger={word.is_deleted === 0} onClick={() => {
                            let title = word.is_deleted === 0 ? '删除违禁词' : '恢复违禁词'
                            let content = word.is_deleted === 0 ? `确定要删除违禁词（‘${word.word}’）吗` : `确定要恢复违禁词（‘${word.word}’）吗`
                            let then = this
                            confirm({
                                title: title,
                                icon: <ExclamationCircleOutlined />,
                                content: content,
                                okText: '确定',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    axios.delete(`manager/message/prohibit_words?is_deleted=${word.is_deleted === 1 ? 0 : 1}&id=${word.id}`).then(function (resp) {
                                        const list = then.state.data
                                        for (let i = 0; i < list.length; i++) {
                                            if (list[i].id === word.id) {
                                                list[i].is_deleted = word.is_deleted === 0 ? 1 : 0
                                                list[i].isDeleted = word.is_deleted === 1 ? <label style={{ color: '#ff0000' }}>是</label> : '否'
                                                break
                                            }
                                        }
                                        then.setState({
                                            data: list
                                        })
                                    }).catch(function (err) {
                                        message.error(err.msg)
                                    })
                                },
                                onCancel() {
                                },
                            });
                        }}>{word.is_deleted === 0 ? '删除' : '恢复'}</Button>
                    </div>
                }
            },
        ];
        return (
            <div>
                <p>违禁词列表</p>
                <Modal centered title='添加违禁词' visible={showAddDilaog} onCancel={() => {
                    this.setState({
                        showAddDilaog: false
                    })
                }} onOk={this.add.bind(this)} okText='提交' cancelText='取消'>
                    <TextArea value={content} onChange={(e) => {
                        this.setState({
                            content: e.target.value
                        })
                    }} style={{ marginTop: '10px' }} rows={5} maxLength={20} placeholder="请输入违禁词"></TextArea>
                </Modal>
                <Input style={{ width: '20%' }} placeholder='请输入违禁词' onChange={(e) => {
                    this.setState({
                        searchKey: e.target.value
                    })
                }}></Input>
                <Button type="primary" style={{ marginLeft: '10px' }} onClick={() => {
                    this.getData()
                }}>搜索</Button>
                <Button onClick={this.showAddDialog.bind(this)} type="primary" style={{ marginLeft: '20px' }}>添加违禁词</Button>
                <Spin spinning={spinning}>
                    <Table pagination={false} style={{ marginTop: '15px' }} columns={columns} dataSource={data} scroll={{ x: 'auto', y: 'auto' }} />
                    <Pagination showSizeChanger={true} style={{ float: 'right', marginTop: '20px' }} defaultPageSize={pageSize} showTotal={this.showTotal.bind(this)} current={page} total={totalCount} onChange={(page, size) => {
                        this.setState({
                            pageSize: size,
                            page: page
                        }, () => {
                            this.getData()
                        })
                    }} />
                </Spin>

            </div>
        )
    }
}

export default ProhibitWords