import { LIMApp, LIMRoute } from "../modules"
import { LIMContext } from "../modules/base/module"
import { loginModule } from "../modules/login/module"
import { GroupModule } from "./group/module"
import HomeLayout from "./homeLayout"
import Welcome from "./index"
import { MsgModule } from "./msg/module"
import { RedPacketModule } from "./redpacket/module"
import { ReportModule } from "./report/module"
import { SettingModule } from "./setting/module"
import { UserModule } from "./user/module"
import {ToolsModule} from "./tools/module"

class App {
    registers() {
        LIMApp.shared().registerModule(new loginModule()) // 登录
        LIMApp.shared().registerModule(new UserModule()) // 用户
        LIMApp.shared().registerModule(new GroupModule())// 群
        LIMApp.shared().registerModule(new MsgModule())// 消息
        LIMApp.shared().registerModule(new SettingModule())// 设置
        LIMApp.shared().registerModule(new ReportModule())// 举报
        LIMApp.shared().registerModule(new RedPacketModule())// 红包
        LIMApp.shared().registerModule(new ToolsModule())//工具
        this.initBaseMethods()
    }

    init() {
        const modules = LIMApp.shared().getAllModules()
        console.log(modules)
        if (modules) {
            for (const module of modules) {
                module.initModule(new LIMContext())
            }
        }
    }

    initBaseMethods() {
        LIMApp.shared().registerRoute(new LIMRoute("/welcome", Welcome))
        LIMApp.shared().registerRoute(new LIMRoute("/home", HomeLayout))
    }
}


const app = new App()
export default app