import { PickerLocale } from "antd/lib/date-picker/generatePicker";

// 统一合并为完整的 Locale
const locale: PickerLocale = {
    lang: {
        "locale": "zh-CN",
        "placeholder": "选择日期",
        "rangePlaceholder": ["开始日期", "结束日期"],
        "today": "今天",
        "now": "Now",
        "backToToday": "Back to today",
        "ok": "确定",
        "clear": "清除",
        "month": "月",
        "year": "年",
        "timeSelect": "选择时间",
        "dateSelect": "选择日期",
        "monthSelect": "选择月",
        "yearSelect": "选择年",
        "decadeSelect": "Choose a decade",
        "yearFormat": "YYYY",
        "dateFormat": "M/D/YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        "monthBeforeYear": true,
        "previousMonth": "Previous month (PageUp)",
        "nextMonth": "Next month (PageDown)",
        "previousYear": "Last year (Control + left)",
        "nextYear": "Next year (Control + right)",
        "previousDecade": "Last decade",
        "nextDecade": "Next decade",
        "previousCentury": "Last century",
        "nextCentury": "Next century"
      
    },
    timePickerLocale: {
        "placeholder": "Select time"
    },
};

locale.lang.ok = '确 定';

export default locale;